import React, { useEffect } from 'react';
import momentTimezone from 'moment-timezone';
import LinkList from '../LinkList';
import { getAgeAndBirthMessage } from '../Helpers';
import NavBar from '../NavBar';
import Gallery, { MyImage } from '../Gallery';
import { addRoute } from '../../GlobalRoutes';
import ThomasJrsFirstYear from './ThomasJr/ThomasJr\'s_First_Year.gif';
import ThomasJrs_Dance from './ThomasJr/ThomasJrs_Dance.mp4';
import { myChildrenlinks } from './index';

const links = [
  { text: 'Thomas Jr Home', url: '/my-children-thomasjr' },
];

const thomasJrYearMappings = {
  'Artwork': {
    images: require.context('./ThomasJr/Artwork', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/Artwork/descriptions.json')
  },
  '17thYear': {
    images: require.context('./ThomasJr/17thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/17thYear/descriptions.json')
  },
  '16thYear': {
    images: require.context('./ThomasJr/16thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/16thYear/descriptions.json')
  },
  '15thYear': {
    images: require.context('./ThomasJr/15thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/15thYear/descriptions.json')
  },
  '14thYear': {
    images: require.context('./ThomasJr/14thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/14thYear/descriptions.json')
  },
  '13thYear': {
    images: require.context('./ThomasJr/13thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/13thYear/descriptions.json')
  },
  '12thYear': {
    images: require.context('./ThomasJr/12thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/12thYear/descriptions.json')
  },
  '11thYear': {
    images: require.context('./ThomasJr/11thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/11thYear/descriptions.json')
  },
  '10thYear': {
    images: require.context('./ThomasJr/10thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/10thYear/descriptions.json')
  },
  '9thYear': {
    images: require.context('./ThomasJr/9thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/9thYear/descriptions.json')
  },
  '8thYear': {
    images: require.context('./ThomasJr/8thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/8thYear/descriptions.json')
  },
  '7thYear': {
    images: require.context('./ThomasJr/7thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/7thYear/descriptions.json')
  },
  '6thYear': {
    images: require.context('./ThomasJr/6thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/6thYear/descriptions.json')
  },
  '5thYear': {
    images: require.context('./ThomasJr/5thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/5thYear/descriptions.json')
  },
  '4thYear': {
    images: require.context('./ThomasJr/4thYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/4thYear/descriptions.json')
  },
  '3rdYear': {
    images: require.context('./ThomasJr/3rdYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/3rdYear/descriptions.json')
  },
  '2ndYear': {
    images: require.context('./ThomasJr/2ndYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/2ndYear/descriptions.json')
  },
  '1stYear': {
    images: require.context('./ThomasJr/1stYear', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/1stYear/descriptions.json')
  },
  'Ultrasounds': {
    images: require.context('./ThomasJr/Ultrasounds', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/Ultrasounds/descriptions.json')
  },
  'Room': {
    images: require.context('./ThomasJr/Room', false, /\.(jpe?g|png|gif|svg)$/i),
    descriptions: require('./ThomasJr/Room/descriptions.json')
  },
};

function Content(props) {
  return (
    <table cellPadding="10" width="100%" height="100%" border="0">
      <tbody>
        <tr>
          <LinkList links={links} />
          <td valign="top">
            <div className="content"><NavBar links={myChildrenlinks} /></div>
            <hr />
            <div className="content">{props.children}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function withYear(year) {
  const Component = function ThomasJrYear() {
    useEffect(() => {
      document.title = `TomsSpot.com - My Children - Thomas Jr - ${year}`;
    }, []);

    const { images, descriptions } = thomasJrYearMappings[year];

    return (
      <Content>
        <Gallery 
          req={images} 
          imageDescriptions={descriptions} 
        />
      </Content>
    );
  };

  addRoute(`/my-children-thomasjr-${year.toLowerCase()}`, <Component />);

  links.push({ text: `${year}`, url: `/my-children-thomasjr-${year.toLowerCase()}` });

  return Component;
}

Object.entries(thomasJrYearMappings).forEach(([year]) => {
  withYear(year);
});

function ThomasJr() {
  useEffect(() => {
    document.title = 'TomsSpot.com - My Children - Thomas Jr';
  });

  const name = "Thomas Richard Lake Jr.";
  const masculine = true;
  const dateOfBirth = momentTimezone.tz('2003-11-11T18:03:00', 'America/Los_Angeles');
  const weight = '7 lbs 15 oz';
  const length = '19 1/4 inches';
  
  return (
    <Content>
      <p>{getAgeAndBirthMessage(name, masculine, dateOfBirth, weight, length)}</p>
      <p><MyImage src={ThomasJrsFirstYear} alt="Thomas Jr's First Year" maxWidth={400} maxHeight={400} setQueryParam={false} /></p>
      <p>Click on one of the links to the left for more pictures, or watch the video below of Thomas Jr doing a dance at Kindercare during summer camp.</p>
      <p>
        <video width="630" height="360" controls>
          <source src={ThomasJrs_Dance} type="video/mp4" />
          <font size="4"><a href={ThomasJrs_Dance} title="Right click and choose Save Target As to download">Thomas Jr's dance at Kindercare during summer camp</a></font>
        </video>       
      </p>
    </Content>
  );
}

export default ThomasJr;
