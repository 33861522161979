import React, { useState, useEffect } from 'react';
import path from 'path';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const MaxThumbnailsWide = 5;

const Thumbnail = ({ src, description, onClick }) => (
  <LazyLoadImage
    src={src}
    alt={description}
    title={description}
    style={{ width: 'auto', height: '100px', margin: '2px' }}
    onClick={onClick}
  />
);

export function MyImage({src, alt, maxWidth=800, maxHeight=600, setQueryParam=false}) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const newWidth = Math.min(img.width, maxWidth || img.width);
      const newHeight = Math.min(img.height, maxHeight || img.height);
      if (newWidth / newHeight > aspectRatio) {
        setHeight(newHeight);
        setWidth(newHeight * aspectRatio);
      } else {
        setWidth(newWidth);
        setHeight(newWidth / aspectRatio);
      }
      if (setQueryParam) {
        const imageName = `${path.parse(src).name.split('.').slice(0, -1).join('.')}${path.extname(src)}`;
        // console.log(`imageName ${imageName}`);
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set('imageName', imageName);
        window.history.pushState({}, '', newUrl);
      }
    };
  }, [src, maxWidth, maxHeight, setQueryParam]);

  return (
    <div className="lazyload-wrapper">
      <LazyLoadImage src={src} title={alt} alt={alt} width={width} height={height} />
    </div>
  );
}

function sortImagesByTaken(images, imageDescriptions) {
  const allImagesHaveTakenDate = imageDescriptions.every(image => image.Taken !== null);

  let sortedImages;
  if (allImagesHaveTakenDate) {
    sortedImages = imageDescriptions
      .sort((a, b) => new Date(a.Taken) - new Date(b.Taken))
      .map(image => images.find(img => img.includes(image.Name.split('.')[0])));
  } else {
    sortedImages = images;
  }

  return sortedImages;
}

function getStartIndex(images, imageName){
  if(imageName === ""){
    return 0;
  }

  return images.findIndex(image => {
    if (image !== undefined && image !== null) {
      try {
        const imageNameWithoutExtension = path.parse(image).name.split('.').slice(0, -1).join('.');
        return `${imageNameWithoutExtension}${path.extname(image)}` === imageName;
      } catch (error) {
        console.error('An error occurred:', error.message);
      }
      return false;
    }
    return false;
  });
}

function Gallery({ req, title, description, imageDescriptions }) {
  const searchParams = new URLSearchParams(window.location.search);
  const imageName = searchParams.get('imageName') || '';
  const images = sortImagesByTaken(req.keys().map(req), imageDescriptions);
  var startImageIndex = getStartIndex(images, imageName);
  const [selectedImage, setSelectedImage] = useState(images[startImageIndex]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(startImageIndex);

  const handleThumbnailClick = (image, index) => {
    startImageIndex = index + 1;   
    setSelectedImage(image);
    setSelectedImageIndex(index);
  };

  // console.log(`imageDescriptions ${JSON.stringify(imageDescriptions)}`);

  const thumbnailsWide = Math.min(MaxThumbnailsWide, images.length);
  const startIndex = Math.max(0, Math.min(selectedImageIndex, images.length - thumbnailsWide));
  const endIndex = Math.min(startIndex + thumbnailsWide - 1, images.length - 1);
  const visibleImages = images.slice(startIndex, endIndex + 1);

  const getImageDescription = (incomingImage) => {
    const imageName = path.parse(incomingImage).name.split('.').slice(0, -1).join('.');
    if (!imageDescriptions) {
      return imageName.replace(/_/g, ' ');
    }
    
    const imageObject = imageDescriptions.find((image) => image.Name.split('.')[0] === imageName);
    
    if (imageObject && imageObject.Description && imageObject.Description !== '') {
      return imageObject.Description;
    } else {
      return imageName.replace(/_/g, ' ');
    }
  };

  const handlePreviousImageClick = () => {
    if (selectedImageIndex > 0) {
      setSelectedImage(images[selectedImageIndex - 1]);
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };
  
  const handleNextImageClick = () => {
    if (selectedImageIndex < images.length - 1) {
      setSelectedImage(images[selectedImageIndex + 1]);
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const handlePreviousClick = (index) => {
    setSelectedImageIndex(Math.max(0, selectedImageIndex - index));
  };

  const handleNextClick = (index) => {
    setSelectedImageIndex(Math.min(images.length - index, selectedImageIndex + index));
  };

  const handleResetStartClick = () => {
    setSelectedImageIndex(0);
  };

  const handleResetEndClick = () => {
    setSelectedImageIndex(images.length - thumbnailsWide);
  };

  const imageDescription = getImageDescription(selectedImage);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <p><font size="6">{title}</font></p>
      {description && description !== "" && (
        <>{description}</>
      )}
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {selectedImageIndex > 0 && (
            <button type="button" onClick={handlePreviousImageClick}>Prev Image</button>
        )}
        <MyImage src={selectedImage} alt={imageDescription} setQueryParam={true} />
        {selectedImageIndex < images.length - 1 && (
          <button type="button" onClick={handleNextImageClick}>Next Image</button>
        )}
      </div>
      <div style={{ marginTop: '10px' }}>
        {imageDescription}
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {images.length > 3 * thumbnailsWide && (
            <>
                <button type="button" onClick={handleResetStartClick} disabled={selectedImageIndex === 0}>First Page</button>
            </>
        )}
        {images.length > thumbnailsWide && (
            <>
                <button type="button" onClick={() => handlePreviousClick(thumbnailsWide)} disabled={selectedImageIndex === 0}>Prev Page</button>
            </>
        )}
        {visibleImages.map((image, index) => (
          <Thumbnail
            key={startIndex + index}
            src={image}
            description={getImageDescription(image)}
            onClick={() => handleThumbnailClick(image, startIndex + index)}
          />
        ))}
        {images.length > thumbnailsWide && (
            <>
                <button type="button" onClick={() => handleNextClick(thumbnailsWide)} disabled={selectedImageIndex >= images.length - thumbnailsWide}>Next Page</button>
            </>
        )}        
        {images.length > 3 * thumbnailsWide && (
            <>
                <button type="button" onClick={handleResetEndClick} disabled={selectedImageIndex >= images.length - thumbnailsWide}>Last Page</button>
            </>
        )}
      </div>
      <div style={{ marginTop: '10px' }}>
        {`Images ${startIndex + 1} thru ${endIndex + 1} out of ${images.length}`}
      </div>
    </div>
  );
};

export default Gallery;