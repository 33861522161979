import React, { useEffect, useRef } from 'react';

const VideoWithPreview = ({ src, width, height, title }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const handleLoadedData = () => {
        video.currentTime = video.duration;
    };

    video.addEventListener('loadeddata', handleLoadedData);

    return () => {
      video.removeEventListener('loadeddata', handleLoadedData);
    };
  });

  return (
    <>
      <video ref={videoRef} width={width} height={height} title={title} controls>
        <source src={src} type="video/mp4" />
      </video>
    </>
  );
};

export default VideoWithPreview;
