import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function NavigationBar(props) {
  const location = useLocation();
  return (
    <nav>
      <ul>
        {props.links.map(link => (
          <li key={link.url}>
            <Link to={link.url} className={location.pathname === link.url ? "active" : ""}>{link.text}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default NavigationBar;