import React, { useEffect } from 'react';
import Lake_Family_Coat_of_Arms from './Lake_Family_Coat_of_Arms.jpg';
import { addRoute } from '../../../GlobalRoutes';
import LinkList from '../../LinkList';
import NavBar from '../../NavBar';
import Gallery, { MyImage } from '../../Gallery';
import { aboutMeLinks } from '../index';
import momentTimezone from 'moment-timezone';
import { getAgeMessage } from '../../Helpers';

const subLinks = [
  { text: 'Family Home', url: '/about-me-family' },
  { text: 'Dad', url: '/about-me-family-dad' },
  { text: 'Mom', url: '/about-me-family-mom' },
  { text: 'Tom', url: '/about-me-family-tom' },
  { text: 'Janet', url: '/about-me-family-janet' },
  { text: 'Pam', url: '/about-me-family-pam' },
  { text: 'Chris', url: '/about-me-family-chris' },
  { text: 'Amanda & Marcus', url: '/about-me-family-amanda-and-marcus' },
  { text: 'Assorted', url: '/about-me-family-assorted' },
  { text: 'Rumwell\'s', url: '/about-me-family-rumwells' },
  { text: 'Family 1961 to 1978', url: '/about-me-family-family-1961-to-1978' },
  { text: 'ARAMCO buildings 1978 to 1979', url: '/about-me-family-aramco-buildings-1978-to-1979' }
];

function Content(props) {
  return (
    <table cellPadding="10" width="100%" height="100%" border="0">
      <tbody>
        <tr>
          <LinkList links={aboutMeLinks} />
          <td valign="top">
            <div className="content">
              <NavBar links={subLinks} />
            </div>  
            <hr />          
            <div className="content">
              {props.children}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export const AboutMeFamily=() => {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family';
  });

  return (
    <Content>
      <p><font size="6">Family Home</font></p>
      <p>This is where I will put family information, pictures, and things that are upcoming. Go to <a href="http://tomlake.tribalpages.com" target="_blank" rel="noreferrer">http://tomlake.tribalpages.com</a> for a family tree.</p>
      <div><MyImage src={Lake_Family_Coat_of_Arms} alt="Lake Family Coat of Arms" /></div>
    </Content>
  );
}

function Dad() {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Dad';
  });
  
  const name = "Theodore Prentice Lake Jr.";

  const description = (
    <>
      <p>{getAgeMessage(name, true, momentTimezone.tz('1934-03-24', 'America/Los_Angeles'), momentTimezone.tz('1998-09-28', 'America/Los_Angeles'))}</p>
    </>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Dad', false, /\.(jpe?g|png|gif|svg)$/i)} title={name} description={description} imageDescriptions={require('./Dad/descriptions.json')} />
    </Content>
  );
}

function Mom() {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Mom';
  });
  const name = "Joan Janet Barbara Hopkin Lake Romer";

  const description = (
    <>
      <p>{getAgeMessage(name, false, momentTimezone.tz('1940-01-03', 'America/Los_Angeles'))}</p>
    </>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Mom', false, /\.(jpe?g|png|gif|svg)$/i)} title={name} description={description} imageDescriptions={require('./Mom/descriptions.json')} />
    </Content>
  );
}

function Tom() {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Tom';
  });
  const name = "Thomas Richard Lake";

  const description = (
    <>
      <p>{getAgeMessage(name, true, momentTimezone.tz('1966-10-20', 'America/Los_Angeles'))}</p>
    </>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Tom', false, /\.(jpe?g|png|gif|svg)$/i)} title={name} description={description} imageDescriptions={require('./Tom/descriptions.json')} />
    </Content>
  );
}

function Janet() {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Janet';
  });
  const name = "Janet Louise Lake";

  const description = (
    <>
      <p>{getAgeMessage(name, false, momentTimezone.tz('1961-12-25', 'America/Los_Angeles'))}</p>
    </>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Janet', false, /\.(jpe?g|png|gif|svg)$/i)} title={name} description={description} imageDescriptions={require('./Janet/descriptions.json')} />
    </Content>
  );
}

function Pam() {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Pam';
  });
  const name = "Pamela Lynn Lake";

  const description = (
    <>
      <p>{getAgeMessage(name, false, momentTimezone.tz('1964-05-07', 'America/Los_Angeles'))}</p>
    </>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Pam', false, /\.(jpe?g|png|gif|svg)$/i)} title={name} description={description} imageDescriptions={require('./Pam/descriptions.json')} />
    </Content>
  );
}

function Chris() {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Chris';
  });
  const name = "Christopher Hamilton Darling";

  const description = (
    <>
      <p>{getAgeMessage(name, true, momentTimezone.tz('1985-09-21', 'America/Los_Angeles'))}</p>
    </>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Chris', false, /\.(jpe?g|png|gif|svg)$/i)} title={name} description={description} imageDescriptions={require('./Chris/descriptions.json')} />
    </Content>
  );
}

function AssortedFamily() {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Assorted Family';
  });
  return (
    <Content>
      <Gallery req={require.context('./Assorted_Family', false, /\.(jpe?g|png|gif|svg)$/i)} title="Assorted Family Pictures" imageDescriptions={require('./Assorted_Family/descriptions.json')} />
    </Content>
  );
}

function AmandaAndMarcus() {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Amanda and Marcus';
  });

  const description = (
    <>
      <p>
        {getAgeMessage("Amanda Renee Carroll Jacobson", false, momentTimezone.tz('1988-07-25', 'America/Los_Angeles'))}<br/>
        {getAgeMessage("Marcus Remington Carroll", true, momentTimezone.tz('1989-11-27', 'America/Los_Angeles'))}
      </p>
    </>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Amanda_and_Marcus', false, /\.(jpe?g|png|gif|svg)$/i)} title="Amanda and Marcus" description={description} imageDescriptions={require('./Amanda_and_Marcus/descriptions.json')} />
    </Content>
  );
}

function Rumwells() {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Rumwells';
  });
  return (
    <Content>
      <Gallery req={require.context('./Rumwell\'s', false, /\.(jpe?g|png|gif|svg)$/i)} imageDescriptions={require('./Rumwell\'s/descriptions.json')} />
    </Content>
  );
}

function Family1961To1978() {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - Family 1961 to 1978';
  });
  return (
    <Content>
      <Gallery req={require.context('./Family_1961_to_1978', false, /\.(jpe?g|png|gif|svg)$/i)} title="Assorted Family Pictures from 1961 to 1978" imageDescriptions={require('./Family_1961_to_1978/descriptions.json')} />
    </Content>
  );
}

function FamilyAramcoBuildings1978To1979() {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Family - ARAMCO buildings 1978 to 1979';
  });
  return (
    <Content>
      <Gallery req={require.context('./ARAMCO_buildings_1978_to_1979', false, /\.(jpe?g|png|gif|svg)$/i)} title="ARAMCO buildings 1978 to 1979" imageDescriptions={require('./ARAMCO_buildings_1978_to_1979/descriptions.json')} />
    </Content>
  );
}

addRoute('/about-me-family-dad', <Dad />);
addRoute('/about-me-family-mom', <Mom />);
addRoute('/about-me-family-tom', <Tom />);
addRoute('/about-me-family-janet', <Janet />);
addRoute('/about-me-family-pam', <Pam />);
addRoute('/about-me-family-chris', <Chris />);
addRoute('/about-me-family-rumwells', <Rumwells />);
addRoute('/about-me-family-amanda-and-marcus', <AmandaAndMarcus />);
addRoute('/about-me-family-assorted', <AssortedFamily />);
addRoute('/about-me-family-family-1961-to-1978', <Family1961To1978 />);
addRoute('/about-me-family-aramco-buildings-1978-to-1979', <FamilyAramcoBuildings1978To1979 />);

export default AboutMeFamily;