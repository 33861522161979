import React, { useEffect } from 'react';
import { addRoute } from '../../GlobalRoutes';
import LinkList from '../LinkList';
import Gallery from '../Gallery';

export const whatsNewLinks = [
  { text: 'Maui April 2022', url: '/whats-new-maui-april-2022' },
  { text: 'Vacation July 2019', url: '/whats-new-vacation-july-2019' },
  { text: 'Washington DC July 2017', url: '/whats-new-dc-july-2017' },
  { text: 'Puyallup Fair 09-11-2016', url: '/whats-new-puyallup-fair-09-11-2016' },
  { text: 'Maui December 2014', url: '/whats-new-maui-december-2014' },
  { text: 'Lake Wenatchee State Park August 2014', url: '/whats-new-lake-wenatchee-august-2014' },
  { text: 'Larrabee July 2014', url: '/whats-new-larrabee-july-2014' },
  { text: 'Vacation August 2013', url: '/whats-new-vacation-august-2013' },
  { text: 'Rosario 06-2013', url: '/whats-new-rosario-06-2013' },
  { text: 'Christmas 2010', url: '/whats-new-christmas-2010' },
  { text: 'Thomas Jr Birthday 2010', url: '/whats-new-thomas-jr-birthday-2010' },
  { text: 'Seattle Kids Marathon 11-27-2010', url: '/whats-new-seattle-kids-marathon-11-27-2010' },
  { text: 'School 09-01-2010', url: '/whats-new-school-09-01-2010' },
  { text: 'Playset', url: '/whats-new-playset' },
  { text: 'Maui 07-2010', url: '/whats-new-maui-07-2010' },
  { text: 'Puyallup Fair 09-12-2009', url: '/whats-new-puyallup-fair-09-12-2009' },
  { text: 'Elbe and Avery puppies', url: '/whats-new-elbe-and-avery-puppies' },
  { text: 'Around Block 05-23-2009', url: '/whats-new-around-block-05-23-2009' },
  { text: 'Zoo 12-27-2008', url: '/whats-new-zoo-12-27-2008' },
  { text: 'Christmas 2008', url: '/whats-new-christmas-2008' },
  { text: 'Snow 12-18-2008', url: '/whats-new-snow-12-18-2008' },
  { text: 'Amanda and Luke Wedding 10-11-2008', url: '/whats-new-amanda-and-luke-wedding-10-11-2008' },
  { text: 'Zoo 09-28-2008', url: '/whats-new-zoo-09-28-2008' },
  { text: 'Microsoft Company Picnic 07-20-2008', url: '/whats-new-microsoft-company-picnic-07-20-2008' },
  { text: 'Zoo 05-17-2008', url: '/whats-new-zoo-05-17-2008' },
  { text: 'Rosario 04-12-2008', url: '/whats-new-rosario-04-12-2008' },
  { text: 'Rosario 03-16-2008', url: '/whats-new-rosario-03-16-2008' },
  { text: 'Pacific Science Center 02-02-2008', url: '/whats-new-pacific-science-center-02-02-2008' },
  { text: 'Pacific Science Center 12-15-2007', url: '/whats-new-pacific-science-center-12-15-2007' },
  { text: 'Remlinger 09-29-2007', url: '/whats-new-remlinger-09-29-2007' },
  { text: 'Larrabee 09-02-2007', url: '/whats-new-larrabee-09-02-2007' },
  { text: 'Aquarium 08-14-2007', url: '/whats-new-aquarium-08-14-2007' },
  { text: 'Day Out With Thomas 07-14-2007', url: '/whats-new-day-out-with-thomas-07-14-2007' },
  { text: 'Zoo 06-08-2007', url: '/whats-new-zoo-06-08-2007' },
  { text: 'Vacation May 2007', url: '/whats-new-vacation-may-2007' },
  { text: 'Zoo 09-24-2006', url: '/whats-new-zoo-09-24-2006' },
  { text: 'Zoo 08-05-2006', url: '/whats-new-zoo-08-05-2006' },
  { text: 'Seattle Center 07-08-2006', url: '/whats-new-seattle-center-07-08-2006' },
  { text: 'Amanda 6-3-2006', url: '/whats-new-amanda-6-3-2006' },
  { text: 'Zoo 05-30-2006', url: '/whats-new-zoo-05-30-2006' },
  { text: 'Joan and Burt May 2006', url: '/whats-new-joan-and-burt-may-2006' },
  { text: 'Juanita Beach 05-17-2006', url: '/whats-new-juanita-beach-05-17-2006' },
  { text: 'Zoo 02-11-2006', url: '/whats-new-zoo-02-11-2006' },
  { text: 'Christmas 2005', url: '/whats-new-christmas-2005' },
  { text: 'Rosario 05-02-2005', url: '/whats-new-rosario-05-02-2005' },
  { text: 'Zoo 04-30-2005', url: '/whats-new-zoo-04-30-2005' },
  { text: 'Thomas Jr\'s first snow January 9 2005', url: '/whats-new-thomas-jrs-first-snow-january-9-2005' },
  { text: 'Vacation June 2004', url: '/whats-new-vacation-june-2004' },
  { text: 'Rosario 08-16-2003', url: '/whats-new-rosario-08-16-2003' },
  { text: 'Larrabee 06-22-2003', url: '/whats-new-larrabee-06-22-2003' },
  { text: 'Rosario 06-01-2003', url: '/whats-new-rosario-06-01-2003' },
  { text: 'Twin Falls 05-24-2003', url: '/whats-new-twin-falls-05-24-2003' },
  { text: 'Alki 10-13-2002', url: '/whats-new-alki-10-13-2002' },
];

const deceptionPassDescription = (
  <p>
    <font size="5">
      For more information on Deception Pass State Park, where Rosario Beach is, click on the <a href="https://parks.wa.gov/find-parks/state-parks/deception-pass-state-park" target="_blank" rel="noreferrer">link</a>.
    </font>
  </p>
);

const lakeWenatcheeDescription = (
  <p>
    <font size="5">
      For more information on Lake Wenatchee State Park click on the <a href="https://parks.wa.gov/find-parks/state-parks/lake-wenatchee-state-park" target="_blank" rel="noreferrer">link</a>.
    </font>
  </p>
);

const larrabeeDescription = (
  <p>
    <font size="5">
      For more information on Larrabee State Park click on the <a href="https://parks.wa.gov/find-parks/state-parks/larrabee-state-park" target="_blank" rel="noreferrer">link</a>.
    </font>
  </p>
);

const woodlandParkZooDescription = (
  <p>
    <font size="5">
      For more information on Woodland Park Zoo click on the <a href="https://www.zoo.org/" target="_blank" rel="noreferrer">link</a>.
    </font>
  </p>
);

const pacificScienceCenterDescription = (
  <p>
    <font size="5">
      For more information on Pacific Science Center click on the <a href="https://pacificsciencecenter.org/" target="_blank" rel="noreferrer">link</a>.
    </font>
  </p>
);

function Content(props) {
  return (
    <table cellPadding="10" width="100%" height="100%" border="0">
      <tbody>
        <tr>
          <LinkList links={whatsNewLinks} />
          <td valign="top">
            <div className="content">{props.children}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function WhatsNewChristmas2010() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Christmas 2010';
  });
  const description = (
    <p>
      <font size="5">
        We went on a cruise followed by Walt Disney World for Christmas 2010.
      </font>
    </p>
  );

  return (
    <Content>
      <Gallery req={require.context('./Christmas_2010', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Christmas 2010'} description={description} imageDescriptions={require('./Christmas_2010/descriptions.json')} />
    </Content>
  );
}

function WhatsNewAlki10132002() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Alki 10-13-2002';
  });
  const description = (
    <p>
      <font size="5">
        Trish and I took Maximus to Alki beach in Seattle, and took a few pictures.
      </font>
    </p>
  );
  return (
    <Content>
      <Gallery req={require.context('./Alki_10-13-2002', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Alki 10-13-2002'} description={description} imageDescriptions={require('./Alki_10-13-2002/descriptions.json')} />
    </Content>
  );
}

function WhatsNewAmanda632006() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Amanda 6-3-2006';
  });

  return (
    <Content>
      <Gallery req={require.context('./Amanda_6-3-2006', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Amanda 6-3-2006'} imageDescriptions={require('./Amanda_6-3-2006/descriptions.json')} />
    </Content>
  );
}

function WhatsNewAmandaandLukeWedding10112008() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Amanda and Luke Wedding 10-11-2008';
  });

  return (
    <Content>
      <Gallery req={require.context('./Amanda_and_Luke_Wedding_10-11-2008', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Amanda and Luke Wedding 10-11-2008'} imageDescriptions={require('./Amanda_and_Luke_Wedding_10-11-2008/descriptions.json')} />
    </Content>
  );
}

function WhatsNewAquarium08142007() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Aquarium 08-14-2007';
  });

  return (
    <Content>
      <Gallery req={require.context('./Aquarium_08-14-2007', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Aquarium 08-14-2007'} imageDescriptions={require('./Aquarium_08-14-2007/descriptions.json')} />
    </Content>
  );
}

function WhatsNewAroundBlock05232009() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Around Block 05-23-2009';
  });
  const description = (
    <p>
      <font size="5">
      On 05-23-2009 we took a walk around the block looking for bugs, leaves and flowers. Along the way we decided to search for words starting with the letters of the alphabet.
      </font>
    </p>
  );
  return (
    <Content>
      <Gallery req={require.context('./Around_Block_05-23-2009', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Around Block 05-23-2009'} description={description} imageDescriptions={require('./Around_Block_05-23-2009/descriptions.json')} />
    </Content>
  );
}

function WhatsNewChristmas2005() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Christmas 2005';
  });

  return (
    <Content>
      <Gallery req={require.context('./Christmas_2005', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Christmas 2005'} imageDescriptions={require('./Christmas_2005/descriptions.json')} />
    </Content>
  );
}

function WhatsNewChristmas2008() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Christmas 2008';
  });

  return (
    <Content>
      <Gallery req={require.context('./Christmas_2008', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Christmas 2008'} imageDescriptions={require('./Christmas_2008/descriptions.json')} />
    </Content>
  );
}

function WhatsNewDayOutWithThomas07142007() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Day Out With Thomas 07-14-2007';
  });

  return (
    <Content>
      <Gallery req={require.context('./Day_Out_With_Thomas_07-14-2007', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Day Out With Thomas 07-14-2007'} imageDescriptions={require('./Day_Out_With_Thomas_07-14-2007/descriptions.json')} />
    </Content>
  );
}

function WhatsNewDCJuly2017() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Washington DC July 2017';
  });

  return (
    <Content>
      <Gallery req={require.context('./DC_July_2017', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Washington DC July 2017'} imageDescriptions={require('./DC_July_2017/descriptions.json')} />
    </Content>
  );
}

function WhatsNewElbeAndAveryPuppies() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Elbe and Avery puppies';
  });
  const description = (
    <p>
      <font size="5">
        Elbe &amp; Avery's puppies on 8-29-2009
      </font>
    </p>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Elbe_and_Avery_puppies', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Elbe and Avery puppies'} description={description} imageDescriptions={require('./Elbe_and_Avery_puppies/descriptions.json')} />
    </Content>
  );
}

function WhatsNewJoanandBurtMay2006() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Joan and Burt May 2006';
  });
  const description = (
    <p>
      <font size="5">
        Joan and Burt's visit May 4 to 14, 2006
      </font>
    </p>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Joan_and_Burt_May_2006', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Joan and Burt May 2006'} description={description} imageDescriptions={require('./Joan_and_Burt_May_2006/descriptions.json')} />
    </Content>
  );
}

function WhatsNewJuanitaBeach05172006() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Juanita Beach 05-17-2006';
  });

  return (
    <Content>
      <Gallery req={require.context('./Juanita_Beach_05-17-2006', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Juanita Beach 05-17-2006'} imageDescriptions={require('./Juanita_Beach_05-17-2006/descriptions.json')} />
    </Content>
  );
}

function WhatsNewLakeWenatcheeAugust2014() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Lake Wenatchee August 2014';
  });
  
  return (
    <Content>
      <Gallery req={require.context('./Lake_Wenatchee_August_2014', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Lake Wenatchee_August_2014'} description={lakeWenatcheeDescription} imageDescriptions={require('./Lake_Wenatchee_August_2014/descriptions.json')} />
    </Content>
  );
}

function WhatsNewLarrabee06222003() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Larrabee 06-22-2003';
  });
  
  return (
    <Content>
      <Gallery req={require.context('./Larrabee_06-22-2003', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Larrabee 06-22-2003'} description={larrabeeDescription} imageDescriptions={require('./Larrabee_06-22-2003/descriptions.json')} />
    </Content>
  );
}

function WhatsNewLarrabee09022007() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Larrabee 09-02-2007';
  });

  return (
    <Content>
      <Gallery req={require.context('./Larrabee_09-02-2007', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Larrabee 09-02-2007'} description={larrabeeDescription} imageDescriptions={require('./Larrabee_09-02-2007/descriptions.json')} />
    </Content>
  );
}

function WhatsNewLarrabeeJuly2014() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Larrabee July 2014';
  });

  return (
    <Content>
      <Gallery req={require.context('./Larrabee_July_2014', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Larrabee July 2014'} description={larrabeeDescription} imageDescriptions={require('./Larrabee_July_2014/descriptions.json')} />
    </Content>
  );
}

function WhatsNewMaui072010() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Maui 07-2010';
  });

  return (
    <Content>
      <Gallery req={require.context('./Maui_07-2010', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Maui 07-2010'} imageDescriptions={require('./Maui_07-2010/descriptions.json')} />
    </Content>
  );
}

function WhatsNewMauiDecember2014() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Maui December 2014';
  });

  return (
    <Content>
      <Gallery req={require.context('./Maui_December_2014', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Maui December 2014'} imageDescriptions={require('./Maui_December_2014/descriptions.json')} />
    </Content>
  );
}

function WhatsNewMauiApril2022() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Maui April 2022';
  });

  return (
    <Content>
      <Gallery req={require.context('./Maui_April_2022', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Maui April 2022'} imageDescriptions={require('./Maui_April_2022/descriptions.json')} />
    </Content>
  );
}

function WhatsNewMicrosoftCompanyPicnic07202008() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Microsoft Company Picnic 07-20-2008';
  });
  const description = (
    <p>
      <font size="5">
      Pictures of the 2008 Microsoft Company Picnic.
      </font>
    </p>
  );
  return (
    <Content>
      <Gallery req={require.context('./Microsoft_Company_Picnic_07-20-2008', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Microsoft Company Picnic 07-20-2008'} description={description} imageDescriptions={require('./Microsoft_Company_Picnic_07-20-2008/descriptions.json')} />
    </Content>
  );
}

function WhatsNewPacificScienceCenter02022008() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Pacific Science Center 02-02-2008';
  });

  return (
    <Content>
      <Gallery req={require.context('./Pacific_Science_Center_02-02-2008', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Pacific Science Center 02-02-2008'} description={pacificScienceCenterDescription} imageDescriptions={require('./Pacific_Science_Center_02-02-2008/descriptions.json')} />
    </Content>
  );
}

function WhatsNewPacificScienceCenter12152007() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Pacific Science Center 12-15-2007';
  });

  return (
    <Content>
      <Gallery req={require.context('./Pacific_Science_Center_12-15-2007', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Pacific Science Center 12-15-2007'} description={pacificScienceCenterDescription} imageDescriptions={require('./Pacific_Science_Center_12-15-2007/descriptions.json')} />
    </Content>
  );
}

function WhatsNewPlayset() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Playset';
  });
  const description = (
    <p>
      <font size="5">
      The new playset was put together on 7/5/2010.
      </font>
    </p>
  );

  return (
    <Content>
      <Gallery req={require.context('./Playset', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Playset'} description={description} imageDescriptions={require('./Playset/descriptions.json')}  />
    </Content>
  );
}

function WhatsNewPuyallupFair09122009() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Puyallup Fair 09-12-2009';
  });
  const description = (
    <p>
      <font size="5">
        For more information on Puyallup Fair click on the <a href="https://www.thefair.com/" target="_blank" rel="noreferrer">link</a>.
      </font>
    </p>
  );
  return (
    <Content>
      <Gallery req={require.context('./Puyallup_Fair_09-12-2009', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Puyallup Fair 09-12-2009'} description={description} imageDescriptions={require('./Puyallup_Fair_09-12-2009/descriptions.json')} />
    </Content>
  );
}

function WhatsNewPuyallupFair09112016() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Puyallup Fair 09-11-2016';
  });
  const description = (
    <p>
      <font size="5">
        For more information on Puyallup Fair click on the <a href="https://www.thefair.com/" target="_blank" rel="noreferrer">link</a>.
      </font>
    </p>
  );
  return (
    <Content>
      <Gallery req={require.context('./Puyallup_Fair_09-11-2016', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Puyallup Fair 09-11-2016'} description={description} imageDescriptions={require('./Puyallup_Fair_09-11-2016/descriptions.json')} />
    </Content>
  );
}

function WhatsNewRemlinger09292007() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Remlinger 09-29-2007';
  });
  const description = (
    <p>
      <font size="5">
        For more information on Remlinger Farms click on the <a href="https://remlingerfarms.com/park/" target="_blank" rel="noreferrer">link</a>.
      </font>
    </p>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Remlinger_09-29-2007', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Remlinger 09-29-2007'} description={description} imageDescriptions={require('./Remlinger_09-29-2007/descriptions.json')} />
    </Content>
  );
}

function WhatsNewRosario062013() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Rosario 06-2013';
  });

  return (
    <Content>
      <Gallery req={require.context('./Rosario_06-2013', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Rosario 06-2013'} description={deceptionPassDescription} imageDescriptions={require('./Rosario_06-2013/descriptions.json')} />
    </Content>
  );
}

function WhatsNewRosario03162008() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Rosario 03-16-2008';
  });

  return (
    <Content>
      <Gallery req={require.context('./Rosario_03-16-2008', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Rosario 03-16-2008'} description={deceptionPassDescription} imageDescriptions={require('./Rosario_03-16-2008/descriptions.json')} />
    </Content>
  );
}

function WhatsNewRosario04122008() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Rosario 04-12-2008';
  });

  return (
    <Content>
      <Gallery req={require.context('./Rosario_04-12-2008', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Rosario 04-12-2008'} description={deceptionPassDescription} imageDescriptions={require('./Rosario_04-12-2008/descriptions.json')} />
    </Content>
  );
}

function WhatsNewRosario05022005() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Rosario 05-02-2005';
  });

  return (
    <Content>
      <Gallery req={require.context('./Rosario_05-02-2005', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Rosario 05-02-2005'} description={deceptionPassDescription} imageDescriptions={require('./Rosario_05-02-2005/descriptions.json')} />
    </Content>
  );
}

function WhatsNewRosario06012003() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Rosario 06-01-2003';
  });

  return (
    <Content>
      <Gallery req={require.context('./Rosario_06-01-2003', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Rosario 06-01-2003'} description={deceptionPassDescription} imageDescriptions={require('./Rosario_06-01-2003/descriptions.json')} />
    </Content>
  );
}

function WhatsNewRosario08162003() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Rosario 08-16-2003';
  });

  return (
    <Content>
      <Gallery req={require.context('./Rosario_08-16-2003', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Rosario 08-16-2003'} description={deceptionPassDescription} imageDescriptions={require('./Rosario_08-16-2003/descriptions.json')} />
    </Content>
  );
}

function WhatsNewSchool09012010() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - School 09-01-2010';
  });

  return (
    <Content>
      <Gallery req={require.context('./School_09-01-2010', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'School 09-01-2010'} imageDescriptions={require('./School_09-01-2010/descriptions.json')} />
    </Content>
  );
}

function WhatsNewSeattleCenter07082006() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Seattle Center 07-08-2006';
  });

  return (
    <Content>
      <Gallery req={require.context('./Seattle_Center_07-08-2006', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Seattle Center 07-08-2006'} imageDescriptions={require('./Seattle_Center_07-08-2006/descriptions.json')} />
    </Content>
  );
}

function WhatsNewSeattleKidsMarathon11272010() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Seattle Kids Marathon 11-27-2010';
  });

  return (
    <Content>
      <Gallery req={require.context('./Seattle_Kids_Marathon_11-27-2010', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Seattle Kids Marathon 11-27-2010'} imageDescriptions={require('./Seattle_Kids_Marathon_11-27-2010/descriptions.json')} />
    </Content>
  );
}

function WhatsNewSnow12182008() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Snow 12-18-2008';
  });

  return (
    <Content>
      <Gallery req={require.context('./Snow_12-18-2008', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Snow 12-18-2008'} imageDescriptions={require('./Snow_12-18-2008/descriptions.json')} />
    </Content>
  );
}

function WhatsNewThomasJrsFirstSnowJanuary92005() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Thomas Jr\'s first snow January 9 2005';
  });
  const description = (
    <p>
      <font size="5">
        We took him to Grass Lawn Park which is just down the street. There also some pictures of the ducks and swans in the pond on the way to the park.
      </font>
    </p>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Thomas_Jr\'s_first_snow_January_9_2005', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Thomas Jr\'s first snow January 9 2005'} description={description} imageDescriptions={require('./Thomas_Jr\'s_first_snow_January_9_2005/descriptions.json')} />
    </Content>
  );
}

function WhatsNewThomasJrBirthday2010() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Thomas Jr Birthday 2010';
  });
  
  return (
    <Content>
      <Gallery req={require.context('./Thomas_Jr_Birthday_2010', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Thomas Jr Birthday 2010'} imageDescriptions={require('./Thomas_Jr_Birthday_2010/descriptions.json')} />
    </Content>
  );
}

function WhatsNewTwinFalls05242003() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Twin Falls 05-24-2003';
  });

  const description = (
    <p>
      <font size="5">
        Twin falls are on the south fork of the Snoqualmie river. For more information, click on the <a href="https://www.wta.org/go-hiking/hikes/twin-falls-state-park" target="_blank" rel="noreferrer">link</a>.
      </font>
    </p>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Twin_Falls_05-24-2003', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Twin Falls 05-24-2003'} description={description} imageDescriptions={require('./Twin_Falls_05-24-2003/descriptions.json')} />
    </Content>
  );
}

function WhatsNewVacationJune2004() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Vacation June 2004';
  });
  const description = (
    <p>
      <font size="5">
        We drove to Tucson, Arizona and Las Cruces, New Mexico.
      </font>
    </p>
  );
  
  return (
    <Content>
      <Gallery req={require.context('./Vacation_June_2004', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Vacation June 2004'} description={description} imageDescriptions={require('./Vacation_June_2004/descriptions.json')} />
    </Content>
  );
}

function WhatsNewVacationMay2007() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Vacation May 2007';
  });

  return (
    <Content>
      <Gallery req={require.context('./Vacation_May_2007', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Vacation May 2007'} imageDescriptions={require('./Vacation_May_2007/descriptions.json')} />
    </Content>
  );
}

function WhatsNewVacationAugust2013() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Vacation August 2013';
  });

  const vacationJuly2013Description = (
    <p>
      <font size="5">
        On the trip Thomas, Tiffany and I went to <a href="https://www.nps.gov/crla/index.htm" target="_blank" rel="noreferrer">Crater Lake National Park</a>, <a href="https://hearstcastle.org/" target="_blank" rel="noreferrer">Hearst Castle</a>, <a href="https://www.nps.gov/yose/index.htm" target="_blank" rel="noreferrer">Yosemite National Park</a>, <a href="https://www.usbr.gov/lc/hooverdam/" target="_blank" rel="noreferrer">Hoover Dam</a>, <a href="https://www.nps.gov/grca/index.htm" target="_blank" rel="noreferrer">Grand Canyon National Park</a>, <a href="https://www.nps.gov/brca/index.htm" target="_blank" rel="noreferrer">Bryce Canyon National Park</a> and <a href="https://www.nps.gov/zion/index.htm" target="_blank" rel="noreferrer">Zion National Park</a>.
      </font>
    </p>
  );

  return (
    <Content>
      <Gallery req={require.context('./Vacation_August_2013', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Vacation August 2013'} description={vacationJuly2013Description} imageDescriptions={require('./Vacation_August_2013/descriptions.json')} />
    </Content>
  );
}

function WhatsNewVacationJuly2019() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Vacation July 2019';
  });

  const vacationJuly2019Description = (
    <p>
      <font size="5">
        On the trip Thomas, Tiffany and I went to <a href="https://www.tfid.org/" target="_blank" rel="noreferrer">Twin Falls, ID</a>, <a href="https://en.wikipedia.org/wiki/Lake_Powell" target="_blank" rel="noreferrer">Lake Powel</a>, <a href="https://www.nps.gov/grca/planyourvisit/north-rim.htm" target="_blank" rel="noreferrer">Grand Canyon North Rim</a>, <a href="https://www.nps.gov/zion/index.htm" target="_blank" rel="noreferrer">Zion National Park</a>, <a href="https://www.nps.gov/arch/index.htm" target="_blank" rel="noreferrer">Arches National Park</a> and <a href="https://www.nps.gov/yell/index.htm" target="_blank" rel="noreferrer">Yellowstone National Park</a>.
      </font>
    </p>
  );

  return (
    <Content>
      <Gallery req={require.context('./Vacation_July_2019', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Vacation July 2019'} description={vacationJuly2019Description} imageDescriptions={require('./Vacation_July_2019/descriptions.json')} />
    </Content>
  );
}

function WhatsNewZoo02112006() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Zoo 02-11-2006';
  });

  return (
    <Content>
      <Gallery req={require.context('./Zoo_02-11-2006', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Woodland Park Zoo 02-11-2006'} description={woodlandParkZooDescription} imageDescriptions={require('./Zoo_02-11-2006/descriptions.json')} />
    </Content>
  );
}

function WhatsNewZoo04302005() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Zoo 04-30-2005';
  });

  return (
    <Content>
      <Gallery req={require.context('./Zoo_04-30-2005', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Woodland Park Zoo 04-30-2005'} description={woodlandParkZooDescription} imageDescriptions={require('./Zoo_04-30-2005/descriptions.json')} />
    </Content>
  );
}

function WhatsNewZoo05172008() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Zoo 05-17-2008';
  });

  return (
    <Content>
      <Gallery req={require.context('./Zoo_05-17-2008', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Woodland Park Zoo 05-17-2008'} description={woodlandParkZooDescription} imageDescriptions={require('./Zoo_05-17-2008/descriptions.json')} />
    </Content>
  );
}

function WhatsNewZoo05302006() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Zoo 05-30-2006';
  });

  return (
    <Content>
      <Gallery req={require.context('./Zoo_05-30-2006', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Woodland Park Zoo 05-30-2006'} description={woodlandParkZooDescription} imageDescriptions={require('./Zoo_05-30-2006/descriptions.json')} />
    </Content>
  );
}

function WhatsNewZoo06082007() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Zoo 06-08-2007';
  });

  return (
    <Content>
      <Gallery req={require.context('./Zoo_06-08-2007', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Woodland Park Zoo 06-08-2007'} description={woodlandParkZooDescription} imageDescriptions={require('./Zoo_06-08-2007/descriptions.json')} />
    </Content>
  );
}

function WhatsNewZoo08052006() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Zoo 08-05-2006';
  });

  return (
    <Content>
      <Gallery req={require.context('./Zoo_08-05-2006', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Woodland Park Zoo 08-05-2006'} description={woodlandParkZooDescription} imageDescriptions={require('./Zoo_08-05-2006/descriptions.json')} />
    </Content>
  );
}

function WhatsNewZoo09242006() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Zoo 09-24-2006';
  });

  return (
    <Content>
      <Gallery req={require.context('./Zoo_09-24-2006', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Woodland Park Zoo 09-24-2006'} description={woodlandParkZooDescription} imageDescriptions={require('./Zoo_09-24-2006/descriptions.json')} />
    </Content>
  );
}

function WhatsNewZoo09282008() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Zoo 09-28-2008';
  });

  return (
    <Content>
      <Gallery req={require.context('./Zoo_09-28-2008', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Woodland Park Zoo 09-28-2008'} description={woodlandParkZooDescription} imageDescriptions={require('./Zoo_09-28-2008/descriptions.json')} />
    </Content>
  );
}

function WhatsNewZoo12272008() {
  useEffect(() => {
    document.title = 'TomsSpot.com - What\'s New - Zoo 12-27-2008';
  });

  const description = (
    <p>
      <font size="5">
        For more information on Reid Park Zoo click on the <a href="https://reidparkzoo.org/" target="_blank" rel="noreferrer">link</a>.
      </font>
    </p>
  );

  return (
    <Content>
      <Gallery req={require.context('./Zoo_12-27-2008', false, /\.(jpe?g|png|gif|svg)$/i)}  title={'Reid Park Zoo 12-27-2008'} description={description} imageDescriptions={require('./Zoo_12-27-2008/descriptions.json')}  />
    </Content>
  );
}

addRoute('/whats-new-alki-10-13-2002', <WhatsNewAlki10132002 />);
addRoute('/whats-new-amanda-6-3-2006', <WhatsNewAmanda632006 />);
addRoute('/whats-new-amanda-and-luke-wedding-10-11-2008', <WhatsNewAmandaandLukeWedding10112008 />);
addRoute('/whats-new-aquarium-08-14-2007', <WhatsNewAquarium08142007 />);
addRoute('/whats-new-around-block-05-23-2009', <WhatsNewAroundBlock05232009 />);
addRoute('/whats-new-christmas-2005', <WhatsNewChristmas2005 />);
addRoute('/whats-new-christmas-2008', <WhatsNewChristmas2008 />);
addRoute('/whats-new-christmas-2010', <WhatsNewChristmas2010 />);
addRoute('/whats-new-day-out-with-thomas-07-14-2007', <WhatsNewDayOutWithThomas07142007 />);
addRoute('/whats-new-dc-july-2017', <WhatsNewDCJuly2017/>);
addRoute('/whats-new-elbe-and-avery-puppies', <WhatsNewElbeAndAveryPuppies />);
addRoute('/whats-new-joan-and-burt-may-2006', <WhatsNewJoanandBurtMay2006 />);
addRoute('/whats-new-juanita-beach-05-17-2006', <WhatsNewJuanitaBeach05172006 />);
addRoute('/whats-new-lake-wenatchee-august-2014', <WhatsNewLakeWenatcheeAugust2014 />);
addRoute('/whats-new-larrabee-06-22-2003', <WhatsNewLarrabee06222003 />);
addRoute('/whats-new-larrabee-09-02-2007', <WhatsNewLarrabee09022007 />);
addRoute('/whats-new-larrabee-july-2014', <WhatsNewLarrabeeJuly2014 />);
addRoute('/whats-new-maui-07-2010', <WhatsNewMaui072010 />);
addRoute('/whats-new-maui-december-2014', <WhatsNewMauiDecember2014 />);
addRoute('/whats-new-maui-april-2022', <WhatsNewMauiApril2022 />);
addRoute('/whats-new-microsoft-company-picnic-07-20-2008', <WhatsNewMicrosoftCompanyPicnic07202008 />);
addRoute('/whats-new-pacific-science-center-02-02-2008', <WhatsNewPacificScienceCenter02022008 />);
addRoute('/whats-new-pacific-science-center-12-15-2007', <WhatsNewPacificScienceCenter12152007 />);
addRoute('/whats-new-playset', <WhatsNewPlayset />);
addRoute('/whats-new-puyallup-fair-09-12-2009', <WhatsNewPuyallupFair09122009 />);
addRoute('/whats-new-puyallup-fair-09-11-2016', <WhatsNewPuyallupFair09112016 />);
addRoute('/whats-new-remlinger-09-29-2007', <WhatsNewRemlinger09292007 />);
addRoute('/whats-new-rosario-06-2013', <WhatsNewRosario062013 />);
addRoute('/whats-new-rosario-03-16-2008', <WhatsNewRosario03162008 />);
addRoute('/whats-new-rosario-04-12-2008', <WhatsNewRosario04122008 />);
addRoute('/whats-new-rosario-05-02-2005', <WhatsNewRosario05022005 />);
addRoute('/whats-new-rosario-06-01-2003', <WhatsNewRosario06012003 />);
addRoute('/whats-new-rosario-08-16-2003', <WhatsNewRosario08162003 />);
addRoute('/whats-new-school-09-01-2010', <WhatsNewSchool09012010 />);
addRoute('/whats-new-seattle-center-07-08-2006', <WhatsNewSeattleCenter07082006 />);
addRoute('/whats-new-seattle-kids-marathon-11-27-2010', <WhatsNewSeattleKidsMarathon11272010 />);
addRoute('/whats-new-snow-12-18-2008', <WhatsNewSnow12182008 />);
addRoute('/whats-new-thomas-jrs-first-snow-january-9-2005', <WhatsNewThomasJrsFirstSnowJanuary92005 />);
addRoute('/whats-new-thomas-jr-birthday-2010', <WhatsNewThomasJrBirthday2010 />);
addRoute('/whats-new-twin-falls-05-24-2003', <WhatsNewTwinFalls05242003 />);
addRoute('/whats-new-vacation-june-2004', <WhatsNewVacationJune2004 />);
addRoute('/whats-new-vacation-may-2007', <WhatsNewVacationMay2007 />);
addRoute('/whats-new-vacation-august-2013', <WhatsNewVacationAugust2013 />);
addRoute('/whats-new-vacation-july-2019', <WhatsNewVacationJuly2019 />);
addRoute('/whats-new-zoo-02-11-2006', <WhatsNewZoo02112006 />);
addRoute('/whats-new-zoo-04-30-2005', <WhatsNewZoo04302005 />);
addRoute('/whats-new-zoo-05-17-2008', <WhatsNewZoo05172008 />);
addRoute('/whats-new-zoo-05-30-2006', <WhatsNewZoo05302006 />);
addRoute('/whats-new-zoo-06-08-2007', <WhatsNewZoo06082007 />);
addRoute('/whats-new-zoo-08-05-2006', <WhatsNewZoo08052006 />);
addRoute('/whats-new-zoo-09-24-2006', <WhatsNewZoo09242006 />);
addRoute('/whats-new-zoo-09-28-2008', <WhatsNewZoo09282008 />);
addRoute('/whats-new-zoo-12-27-2008', <WhatsNewZoo12272008 />);

export default WhatsNewVacationJuly2019;