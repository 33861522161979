import React, { useEffect, useState } from 'react';
import momentTimezone from 'moment-timezone';
import { addRoute } from '../../GlobalRoutes';
import LinkList from '../LinkList';
import Gallery from '../Gallery';
import { getAgeMessage } from '../Helpers';

export const petsLinks = [
  { text: 'Juliette', url: '/pets-juliette' },
  { text: 'Rosalie', url: '/pets-rosalie' },
  { text: 'Zeus', url: '/pets-zeus' },
  { text: 'Mousse', url: '/pets-mousse' },
  { text: 'Optimus', url: '/pets-optimus' },
];

function Content(props) {
  return (
    <table cellPadding="10" width="100%" height="100%" border="0">
      <tbody>
        <tr>
          <LinkList links={petsLinks} />
          <td valign="top">
            <div className="content">{props.children}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

const petsData = [
  {
    name: 'Juliette',
    masculine: false,
    dateOfBirth: '2023-12-11',
    dateOfDeath: null,
    description: (
      <>
        We picked up Juliette on February 12, 2024 from <a href="https://www.puppycreek.com/" target="_blank" rel="noreferrer">PuppyCreek Kennels</a> in Witter, AR.
      </>
    ),
    images: require.context('./Juliette', false, /\.(jpe?g|png|gif|svg)$/i),
    imageDescriptions: require('./Juliette/descriptions.json'),
  },
  {
    name: 'Rosalie',
    masculine: false,
    dateOfBirth: '2020-11-09',
    dateOfDeath: null,
    description: (
      <>
        We picked up Rosalie on January 4, 2021 from <a href="https://www.puppycreek.com/" target="_blank" rel="noreferrer">PuppyCreek Kennels</a> in Witter, AR.
      </>
    ),
    images: require.context('./Rosalie', false, /\.(jpe?g|png|gif|svg)$/i),
    imageDescriptions: require('./Rosalie/descriptions.json'),
  },
  {
    name: 'Zeus',
    masculine: true,
    dateOfBirth: '2017-07-14',
    dateOfDeath: '2024-01-12',
    description: (
      <>
        We picked up Zeus on September 9, 2017 from <a href="https://www.windyriverranchrottweilers.com/" target="_blank" rel="noreferrer">Windy River Ranch Rottweilers</a> in Lyle, WA.
      </>
    ),
    images: require.context('./Zeus', false, /\.(jpe?g|png|gif|svg)$/i),
    imageDescriptions: require('./Zeus/descriptions.json'),
  },
  {
    name: 'Optimus',
    masculine: true,
    dateOfBirth: '2009-10-28',
    dateOfDeath: '2020-10-16',
    description: (
      <>
        We picked up Optimus on December 15, 2009.
      </>
    ),
    images: require.context('./Optimus', false, /\.(jpe?g|png|gif|svg)$/i),
    imageDescriptions: require('./Optimus/descriptions.json'),
  },
  {
    name: 'Mousse',
    masculine: true,
    dateOfBirth: '2012-12-29',
    dateOfDeath: '2016-06-21',
    description: (
      <>
        We got Mousse on February 23, 2013.
      </>
    ),
    images: require.context('./Mousse', false, /\.(jpe?g|png|gif|svg)$/i),
    imageDescriptions: require('./Mousse/descriptions.json'),
  },
];

function PetPage({ name, masculine, dateOfBirth, dateOfDeath, description, images, imageDescriptions }) {
  const [loaded, setLoaded] = useState(false);
  const [petData, setPetData] = useState({});

  useEffect(() => {
    document.title = `TomsSpot.com - Pets - ${name}`;
    setPetData({ name, masculine, dateOfBirth, dateOfDeath, description, images, imageDescriptions });
    setLoaded(true);
  }, [name, masculine, dateOfBirth, dateOfDeath, description, images, imageDescriptions]);

  if (!loaded) {
    return <div>Loading...</div>;
  }

  const birthDate = momentTimezone.tz(petData.dateOfBirth, 'America/Los_Angeles');
  const deathDate = petData.dateOfDeath ? momentTimezone.tz(petData.dateOfDeath, 'America/Los_Angeles') : null;

  const fullDescription = (
    <>
      <p>{petData.description}</p>
      <p>{getAgeMessage(petData.name, petData.masculine, birthDate, deathDate)}</p>
    </>
  );

  return (
    <Content>
      <Gallery
        key={petData.name} // Add a key to force re-render
        req={petData.images}
        title={petData.name}
        description={fullDescription}
        imageDescriptions={petData.imageDescriptions}
      />
    </Content>
  );
}

petsData.forEach((pet) => {
  addRoute(`/pets-${pet.name.toLowerCase()}`, <PetPage {...pet} />);
});

export default PetPage;
