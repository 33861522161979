import React, { useEffect } from 'react';
import LinkList from '../../LinkList';
import { aboutMeLinks } from '../index';
import Canadian_View_of_US from './Canadian_View_of_US.jpg';
import kidstoned from './Kid_Stoned_Chewable_Valium.mp4';
import { MyImage } from '../../Gallery';
import VideoWithPreview from '../../VideoWithPreview';

export const comedyLinks = [
  { text: 'Comedy Home', url: '/about-me-comedy' },
  { text: 'Blonde Jokes', url: '/about-me-comedy-blonde-jokes' }
];

function Content(props) {
  return (
    <table cellPadding="10" width="100%" height="100%" border="0">
    <tbody>
      <tr>
        <LinkList links={aboutMeLinks} />
        <td valign="top">
          <div className="content">{props.children}</div>
        </td>
      </tr>
    </tbody>
  </table>
  );
}



function Comedy() {
  useEffect(() => {
    document.title = 'TomsSpot.com - About Me - Comedy';
  });

  const horseJokes = [
    "Buying a stronger whip.",
    "Changing riders.",
    "Threatening the horse with termination.",
    "Appointing a committee to study the horse.",
    "Arranging to visit other countries to see how others ride dead horses.",
    "Lowering the standards so that dead horses can be included.",
    "Reclassifying the dead horse as 'living impaired.'",
    "Hiring outside contractors to ride the dead horse.",
    "Harnessing several dead horses together to increase the speed.",
    "Providing additional funding and/or training to increase the dead horse's performance.",
    "Doing a productivity study to see if lighter riders would improve the dead horse's performance.",
    "Declaring that, as the dead horse does not have to be fed, it is less costly, carries a lower overhead, and, therefore, contributes substantially more to the bottom line of the economy than do some other horses.",
    "Rewriting the expected performance requirements for all horses.",
    "Promoting the dead horse to a supervisory position."
  ];  

  const stateJokes = [
    {
      state: "Tennessee",
      content: "A man successfully broke into a bank after hours and stole the bank's video camera. While it was recording, remotely (That is, the videotape recorder was located elsewhere in the bank, so he didn't get the videotape of himself stealing the camera)."
    },
    {
      state: "Louisiana",
      content: "A man walked into a Circle-K, put a $20 bill on the counter and asked for change. When the clerk opened the cash drawer, the man pulled a gun and asked for all the cash in the register, which the clerk promptly provided. The man took the cash from the clerk and fled, leaving the $20 bill on the counter. The total amount of cash he got from the drawer? Fifteen dollars."
    },
    {
      state: "Florida",
      content: "[Uh, pardon our English] A thief burst into the bank one day wearing a ski mask and carrying a gun. Aiming his gun at the guard, the thief yelled, FREEZE, MOTHER-STICKERS, THIS IS A FUCK-UP!\" For a moment, everyone was silent. Then the snickers started. The guard completely lost it and doubled over laughing. It probably saved his life, because he'd been about to draw his gun. He couldn't have drawn and fired before the thief got him. The thief ran away and is still at large. In memory of the event, the bank later put a plaque on the wall engraved \"Freeze, Mother-Stickers, this is a fuck-up!\""
    },
    {
      state: "Arkansas",
      content: "Seems this guy wanted some beer pretty badly. He decided that he'd just throw a cinder block thru a liquor store window, grab some booze, and run. So he lifted the cinder block and heaved it over his head at the window. The cinder block bounced back and hit the would-be thief on the head, knocking him unconscious. Seems the liquor store window was made of Plexi-Glass. The whole event was caught on videotape."
    },
    {
      state: "New York",
      content: "As a female shopper exited a convenience store, a man grabbed her purse and ran. The clerk called 911 immediately and the woman was able to give them a detailed description of the snatcher. Within minutes, the police had apprehended the snatcher. They put him in the cruiser and drove back to the store. The thief was then taken out of the car and told to stand there for a positive ID. To which he replied \"Yes Officer..that's her. That's the lady I stole the purse from.\""
    },
    {
      state: "Washington State",
      content: "When a man attempted to siphon gasoline from a motorhome parked on a Seattle street, he got much more than he bargained for. Police arrived at the scene to find an ill man curled up next to a motorhome near spilled sewage. A police spokesman said that the man admitted to trying to steal gasoline and plugged his hose into the motorhome's sewage tank by mistake. The owner of the vehicle declined to press charges, saying that it was the best laugh he'd ever had."
    },
    {
      state: "Newark",
      content: "A woman was reporting her car as stolen, and mentioned that there was a car phone in it. The policeman taking the report called the phone, and told the guy that answered that he had read the ad in the newspaper and wanted to buy the car. They arranged to meet, and the thief was arrested."
    },
    {
      state: "Michigan",
      content: "The Ann Arbor News crime column reported that a man walked into a Burger King in Ypsilanti, Michigan at 7:50am, flashed a gun and demanded cash. The clerk turned him down because he said he couldn't open the cash register without a food order. When the man ordered onion rings, the clerk said they weren't available for breakfast. The man, frustrated, walked away."
    },
    {
      state: "Kentucky",
      content: "Two men tried to pull the front off a cash machine by running a chain from the machine to the bumper of their pickup truck. Instead of pulling the front panel off the machine, though, they pulled the bumper off their truck. Scared, they left the scene and drove home. With the chain still attached to the machine. With their bumper still attached to the chain. With their vehicle's license plate still attached to the bumper."
    }
  ];
  
  const idiotJokes = [
    {
      category: "IDIOTS AT WORK",
      content: "I was signing the receipt for my credit card purchase when the clerk noticed that I had never signed my name on the back of the credit card. She informed me that she could not complete the transaction unless the card was signed. When I asked why, she explained that it was necessary to compare the signature on the credit card with the signature I just signed on the receipt. So I signed the credit card in front of her. She carefully compared that signature to the one I signed on the receipt. As luck would have it, they matched."
    },
    {
      category: "ADVICE FOR IDIOTS",
      content: "An actual tip from page 16 of the HP 'Environmental, Health & Safety Handbook for Employees: 'Blink your eyelids periodically to lubricate your eyes.'"
    },
    {
      category: "IDIOTS IN THE NEIGHBORHOOD",
      content: "I live in a semi-rural area. We recently had a new neighbor call the local township administrative office to request the removal of the Deer Crossing sign on our road. The reason: Many deer were being hit by cars and he no longer wanted them to cross there."
    },
    {
      category: "IDIOTS IN FOOD SERVICE",
      content: "My daughter went to a local Taco Bell and ordered a taco. She asked the individual behind the counter for 'minimal lettuce.' He said he was sorry, but they only had iceberg."
    },
    {
      category: "IDIOT SIGHTINGS Sighting #1",
      content: "I was at the airport, checking in at the gate, when the airport employee asked, 'Has anyone put anything in your baggage without your knowledge?' I said, 'If it was without my knowledge, how would I know?' He smiled and nodded knowingly, 'That's why we ask.'"
    },
    {
      category: "Sighting #2",
      content: "The stoplight on the corner buzzes when it is safe to cross the street. I was crossing with an intellectually challenged co-worker of mine, when she asked if I knew what the buzzer was for. I explained that it signals to blind people when the light is red. She responded, appalled, 'What on earth are blind people doing driving?'"
    },
    {
      category: "Sighting #3",
      content: "At a good-bye lunch for an old and dear coworker who is leaving the company due to 'down-sizing,' our manager spoke up and said, 'this is fun. We should have lunch like this more often.' Not another word was spoken. We just looked at each other like deer staring into the headlights of an approaching truck."
    },
    {
      category: "Sighting #4",
      content: "I worked with an Individual who plugged her power strip back into itself and for the life of her could not understand why her system would not turn on."
    },
    {
      category: "Sighting #5",
      content: "When my husband and I arrived at an automobile dealership to pick up our car, we were told that the keys had been accidentally locked in it. We went to the service department and found a mechanic working feverishly to unlock the driver's side door. As I watched from the passenger's side, I instinctively tried the door handle and discovered it was open. 'Hey,' I announced to the technician, 'it's open!' 'I know,' answered the young man. 'I already got that side.'"
    }
  ];  

  const threeGirlsJoke = [
    "Three girls all worked in the same office with the same female boss. Each day, they noticed the boss left work early. One day the girls decided that, when the boss left, they would leave right behind her. After all, she never called or came back to work, so how would she know they went home early?",
    "The brunette was thrilled to be home early. She did a little gardening, spent playtime with her son, and went to bed early.",
    "The redhead was elated to be able to get in a quick workout at the spa before meeting a dinner date.",
    "The blonde was happy to get home early and surprise her husband, but when she got to her bedroom, she heard a muffled noise from inside. Slowly and quietly, she cracked open the door and was mortified to see her husband in bed with her boss! Gently, she closed the door and crept out of her house.",
    "The next day, at their coffee break, the brunette and redhead planned to leave early again, and they asked the blonde if she was going to go with them.",
    "'No way' the blonde exclaimed. 'I almost got caught yesterday!'"
  ];  

  const insanityJokes = [
    "At lunch time, sit in your parked car w/sunglasses on and point a hair dryer at passing cars. See if they slow down.",
    "Page yourself over the intercom. Don't disguise your voice.",
    "Insist that your e mail address is: Xena-Warrior-Princess@companyname.com OR Elvis-the-King@companyname.com.",
    "Every time someone asks you to do something, ask if they want fries with that.",
    "Encourage your colleagues to join you in a little synchronized chair dancing.",
    "Put your garbage can on your desk and label it 'IN.'",
    "Develop an unnatural fear of staplers.",
    "Put decaf in the coffee maker for 3 weeks. Once everyone has gotten over their caffeine addictions, switch to espresso.",
    "In the memo field of all your checks, write 'for sexual favors.'",
    "Reply to everything someone says with, 'That's what you think.'",
    "Finish all your sentences with 'In accordance with the prophecy.'",
    "Adjust the tint on your monitor so that the brightness level lights up the entire work area. Insist to others that you like it that way.",
    "Dont use any punctuation",
    "As often as possible, skip rather than walk.",
    "Ask people what sex they are and laugh hysterically after they answer.",
    "Specify that your drive-through order is 'to go.'",
    "Sing along at the opera.",
    "Go to a poetry recital and ask why the poems don't rhyme.",
    "Find out where your boss shops and buy exactly the same outfits. Wear them one day after your boss does. (This is especially effective if your boss is of the opposite gender.)",
    "Send e-mail to the rest of the company to tell them what you're doing. For example, 'If anyone needs me, I'll be in the bathroom, in Stall #3.'",
    "Put mosquito netting around your cubicle. Play a tape of jungle sounds all day.",
    "Five days in advance, tell your friends you can't attend their party because you're not in the mood.",
    "Call 911 and ask if 911 is for emergencies.",
    "Call the psychic hot line and don't say anything.",
    "Have your coworkers address you by your wrestling name, Rock Hard.",
    "When the money comes out of the ATM, scream 'I Won!', 'I Won!', '3rd time this week!!!'",
    "When leaving the zoo, start running towards the parking lot, yelling 'Run for your lives, they're loose!'",
    "Tell your boss, 'It's not the voices in my head that bother me, its the voices in your head that do.'",
    "Tell your children over dinner. 'Due to the economy, we are going to have to let one of you go.'",
    "Every time you see a broom, yell 'Honey, your mother is here!'"
  ];
  
  const goodToBeAManJokes = [
    "Your last name stays put.",
    "The garage is all yours.",
    "Wedding plans take care of themselves.",
    "Chocolate is just another snack.",
    "You can be president.",
    "You can wear a white T-shirt to a water park.",
    "Car mechanics tell you the truth.",
    "You don't give a rat's ass if someone notices your new haircut.",
    "The world is your urinal.",
    "You never have to drive to another gas station because this one's just too icky.",
    "Same work, more pay.",
    "Wrinkles add character.",
    "Wedding dress $5000; Tux rental $100.",
    "People never stare at your chest when you're talking to them.",
    "The occasional well-rendered belch is practically expected.",
    "New shoes don't cut, blister, or mangle your feet.",
    "Your pals can be trusted never to trap you with: 'So, notice anything different?'",
    "One mood, ALL the damn time.",
    "Phone conversations are over in 30 seconds flat.",
    "You know stuff about tanks.",
    "A five-day vacation requires only one suitcase.",
    "You can open all your own jars.",
    "Dry cleaners and hair stylist don't rob you blind.",
    "You can leave the motel bed unmade.",
    "You can kill your own food.",
    "You get extra credit for the slightest act of thoughtfulness.",
    "If someone forgets to invite you to something, he or she can still be your friend.",
    "Your underwear is $8.95 for a three-pack.",
    "If you are 34 and single, nobody notices.",
    "Everything on your face stays its original color.",
    "You can quietly enjoy a car ride from the passenger's seat.",
    "Three pairs of shoes are more than enough.",
    "You don't have to clean your apartment if the maid is coming.",
    "You can quietly watch a game with your buddy for hours without ever thinking: 'He must be mad at me.'",
    "You don't mooch off other's desserts.",
    "You can drop by to see a friend without having to bring a little gift.",
    "If another guy shows up at the party in the same outfit, you just might become lifelong friends.",
    "You are not expected to know the names of more than five colors.",
    "You don't have to stop and think of which way to turn a nut on a bolt.",
    "You almost never have strap problems in public.",
    "You are unable to see wrinkles in your clothes.",
    "The same hairstyle lasts for years, maybe decades.",
    "You don't have to shave below your neck.",
    "Your belly usually hides your big hips.",
    "One wallet and one pair of shoes, one color, all seasons.",
    "You can 'do' your nails with a pocketknife.",
    "You have freedom of choice concerning growing a mustache.",
    "Christmas shopping can be accomplished for 25 relatives, on December 24th, in 45 minutes."
  ];
  
  const repairmanJoke = [
    "Mrs. Broomfield's dishwasher quit working, so she called a repairman.",
    "He couldn't accommodate her with an evening appointment, and since she had to go to work the next day, she told him: \"I'll leave the key under the mat. Fix the dishwasher, leave the bill on the counter, and I'll mail you the check. By the way, don't worry about my Rottweiler. He won't bother you. But, whatever you do, do not under any circumstances talk to my parrot!\"",
    "When the repairman arrived at Mrs. Broomfield's apartment the next day, he discovered the biggest and meanest looking Rottweiler he had ever seen.",
    "But, just like she had said, the dog just lay there on the carpet, watching the repairman go about his business.",
    "However, the whole time he was there, the parrot drove him nuts with his incessant squawking and talking. Finally the repairman couldn't contain himself any longer and yelled: \"Shut up, would you just shut up you stupid bird!\"",
    "The parrot sat quietly for a moment, and then replied: \"Sic him, Brutus!\""
  ];
    
  const wordperfectConversation = [
    { text: "Ridge Hall computer assistance; may I help you?", direction: "left" },
    { text: "Yes, well, I'm having trouble with WordPerfect.", direction: "right" },
    { text: "What sort of trouble?", direction: "left" },
    { text: "Well, I was just typing along, and all of a sudden the words went away.", direction: "right" },
    { text: "Went away?", direction: "left" },
    { text: "They disappeared.", direction: "right" },
    { text: "Hmm. So what does your screen look like now?", direction: "left" },
    { text: "Nothing.", direction: "right" },
    { text: "Nothing?", direction: "left" },
    { text: "It's blank; it won't accept anything when I type.", direction: "right" },
    { text: "Are you still in WordPerfect, or did you get out?", direction: "left" },
    { text: "How do I tell?", direction: "right" },
    { text: "Can you see the C: prompt on the screen?", direction: "left" },
    { text: "What's a sea-prompt?", direction: "right" },
    { text: "Never mind, can you move your cursor around the screen?", direction: "left" },
    { text: "There isn't any cursor: I told you, it won't accept anything I type.", direction: "right" },
    { text: "Does your monitor have a power indicator?", direction: "left" },
    { text: "What's a monitor?", direction: "right" },
    { text: "It's the thing with the screen on it that looks like a TV. Does it have a little light that tells you when it's on?", direction: "left" },
    { text: "I don't know.", direction: "right" },
    { text: "Well, then look on the back of the monitor and find where the power cord goes into it. Can you see that?", direction: "left" },
    { text: "Yes, I think so.", direction: "right" },
    { text: "Great. Follow the cord to the plug, and tell me if it's plugged into the wall.", direction: "left" },
    { text: "Yes, it is.", direction: "right" },
    { text: "When you were behind the monitor, did you notice that there were two cables plugged into the back of it, not just one?", direction: "left" },
    { text: "No.", direction: "right" },
    { text: "Well, there are. I need you to look back there again and find the other cable.", direction: "left" },
    { text: "Okay, here it is.", direction: "right" },
    { text: "Follow it for me, and tell me if it's plugged securely into the back of your computer.", direction: "left" },
    { text: "I can't reach.", direction: "right" },
    { text: "You can't reach?", direction: "left" },
    { text: "No.", direction: "right" },
    { text: "Even if you maybe put your knee on something and lean way over?", direction: "left" },
    { text: "Oh, it's not because I don't have the right angle-it's because it's dark.", direction: "right" },
    { text: "Dark?", direction: "left" },
    { text: "Yes, -the office light is off, and the only light I have is coming in from the window.", direction: "right" },
    { text: "Well, turn on the office light then.", direction: "left" },
    { text: "I can't.", direction: "right" },
    { text: "No? Why not?", direction: "left" },
    { text: "Because there's a power failure.", direction: "right" },
    { text: "A power... A power failure? Aha, Okay, we've got it licked now. Do you still have the boxes and manuals and packing stuff your computer came in?", direction: "left" },
    { text: "Well, yes, I keep them in the closet.", direction: "right" },
    { text: "Good. Go get them, and unplug your system and pack it up just like it was when you got it. Then take it back to the store you bought it from.", direction: "left" },
    { text: "Really? Is it that bad?", direction: "right" },
    { text: "Yes, I'm afraid it is.", direction: "left" },
    { text: "Well, all right then, I suppose. What do I tell them?", direction: "right" },
    { text: "Tell them you're too fucking stupid to own a computer.", direction: "left" }
  ];

  const funeralConversation = [
    { text: "Why are you laughing?", direction: "left" },
    { text: "'I was thinking about my own funeral' the man replied.", direction: "right" },
    { text: "What's so funny about that?", direction: "left" },
    { text: "I'm a gynecologist.", direction: "right" }
  ];

  const anagramJokes = [
    { original: "A Decimal Point", rearranged: "I'm a Dot in Place" },
    { original: "A gentleman", rearranged: "Elegant man" },
    { original: "Animosity", rearranged: "Is No Amity" },
    { original: "Astronomer", rearranged: "Moon starer" },
    { original: "Desperation", rearranged: "A Rope Ends It" },
    { original: "Dormitory", rearranged: "Dirty Room" },
    { original: "Eleven plus two", rearranged: "Twelve plus one" },
    { original: "Evangelist", rearranged: "Evil's Agent" },
    { original: "Funeral", rearranged: "Real fun" },
    { original: "George Bush", rearranged: "He bugs Gore" },
    { original: "Mother-in-law", rearranged: "Woman Hitler" },
    { original: "Schoolmaster", rearranged: "The classroom" },
    { original: "Slot Machines", rearranged: "Cash Lost in em" },
    { original: "Snooze Alarms", rearranged: "Alas! No More Z's" },
    { original: "The Earthquakes", rearranged: "That Queer Shake" },
    { original: "The Morse Code", rearranged: "Here Come Dots" },
    { original: "The eyes", rearranged: "They see" },
    { original: "President Clinton of the USA", rearranged: "To copulate he finds interns" }
  ];
  
  const lessonsToLearn = [
    {
      story: "A crow was sitting on a tree, doing nothing all day. A small rabbit saw the crow, and asked him, 'Can I also sit like you and do nothing all day long?' The crow answered: 'Sure, why not.' So, the rabbit sat on the ground below the crow, and rested. All of a sudden, a fox appeared, jumped on the rabbit and ate it.",
      lesson: "To be sitting and doing nothing, you must be sitting very, very high up."
    },
    {
      story: "A turkey was chatting with a bull. 'I would love to be able to get to the top of that tree,' sighed the turkey, 'but I haven't got the energy.' 'Well, why don't you nibble on some of my droppings?' replied the bull. 'They're packed with nutrients.' The turkey pecked at a lump of dung and found that it actually gave him enough strength to reach the first branch of the tree. The next day, after eating some more dung, he reached the second branch. Finally after a fortnight, there he was proudly perched at the top of the tree. Soon he was promptly spotted by a farmer, who shot the turkey out of the tree.",
      lesson: "Bullshit might get you to the top, but it won't keep you there."
    },
    {
      story: "A little bird was flying south for the winter. It was so cold, the bird froze and fell to the ground in a large field. While it was lying there, a cow came by and dropped some dung on it. As the frozen bird lay there in the pile of cow dung, it began to realize how warm it was. The dung was actually thawing him out! He lay there all warm and happy and soon began to sing for joy. A passing cat heard the bird singing and came to investigate. Following the sound, the cat discovered the bird under the pile of cow dung and promptly dug him out and ate him!",
      lesson: [
        "Not everyone who drops shit on you is your enemy.",
        "Not everyone who gets you out of shit is your friend.",
        "And when you're in deep shit, keep your mouth shut!"
      ]
    }
  ];
  
  const proverbsJokes = [
    "Never be afraid to try something new. Remember: amateurs built the Ark, Professionals built the Titanic.",
    "Conscience is what hurts when everything else feels so good.",
    "Love is grand; divorce is several hundred grand.",
    "Politicians and diapers have one thing in common. They should both be changed regularly and for the same reason.",
    "An optimist thinks that this is the best possible world. A pessimist fears that this is true.",
    "In just two days, tomorrow will be yesterday.",
    "I always wanted to be a procrastinator; I never got around to it.",
    "I am a nutritional overachiever.",
    "I am having an out of money experience.",
    "I plan on living forever. So far, so good.",
    "A day without sunshine is like night.",
    "I have kleptomania, but when it gets bad, I take something for it.",
    "Time may be a great healer, but it's a lousy beautician.",
    "Brain cells come and brain cells go, but fat cells live forever.",
    "Age doesn't always bring wisdom, sometimes age comes alone.",
    "Life not only begins at forty, it begins to show.",
    "You don't stop laughing because you grow old, you grow old because you stopped laughing."
  ];
  
  const pearlsOfWisdomJokes = [
    "Any and all compliments can be handled by simply saying 'Why, thank you' (though it helps if you say it with a Southern accent).",
    "Some people are working backstage, some are playing in the orchestra, some are on stage singing, some are in the audience as critics and some are there to applaud. Know who and where you are.",
    "Never give yourself a haircut after three margaritas.",
    "When baking, follow directions. When cooking, go by your own taste.",
    "Never continue dating anyone who is rude to the waiters and doesn't like dogs/cats.",
    "Good sex should involve laughter. Because think about it, it is funny.",
    "You need only two tools. WD-40 and duct tape. If it doesn't move and it should, use WD-40. If it moves and shouldn't, use the tape.",
    "The five most essential words for a healthy, vital relationship 'I apologize' and 'You are right.'",
    "Everyone seems normal until you get to know them.",
    "When you make a mistake, make amends immediately. It's easier to eat crow while it's still warm.",
    "The only really good advice that I remember my mother ever gave me was, 'Go! You might meet somebody!'",
    "If he/she says that you are too good for him/her--believe it.",
    "I've learned to pick my battles; I ask myself, 'Will this matter one year from now? How about one month? One week? One day?",
    "Never pass up an opportunity to pee.",
    "If you woke up breathing, congratulations! You have another chance!",
    "Living well really is the best revenge. Being miserable because of a bad or former relationship just might mean that the other person was right about you.",
    "Knowing how to listen to music is as great a talent as knowing how to make it.",
    "Work is good but it's not that important.",
    "Never underestimate the kindness of your fellow man.",
    "And finally... Be really nice to your friends. You never know when you are going to need them to empty your bedpan."
  ];

  const girlPowerJokes = [
    { question: "What should you do when you see ex-husband rolling around in pain on the ground?", answer: "Shoot him again." },
    { question: "Why do little boys whine?", answer: "They're practicing to be men." },
    { question: "How many men does it take to screw in a lightbulb?", answer: "One he just holds it up there and waits for the world to revolve around him.  Or Alternate answer Three - one to screw in the bulb, and two to listen to him brag about the screwing part." },
    { question: "What do you call a handcuffed man?", answer: "Trustworthy." },
    { question: "What does it mean when a man is in your bed gasping for breath and calling your name?", answer: "You didn't hold the pillow down long enough." },
    { question: "Why does it take 100,000,000 sperm to fertilize one egg?", answer: "Because not one will stop and ask for directions." },
    { question: "What's the best way to kill a man?", answer: "Put a six-pack and a naked woman in front of him and ask him to choose just one." },
    { question: "What do men and pantyhose have in common?", answer: "They either cling, run, or don't fit right in the crotch." },
    { question: "What is the difference between men and women?", answer: "A woman wants one man to satisfy her every need. A man wants every woman to satisfy his one need." },
    { question: "How does a man keep his youth?", answer: "By giving her money, diamonds, and furs." },
    { question: "How do you keep your husband from reading your e-mail?", answer: "Rename the mail folder to 'instruction manuals.'" }
  ];

  const northwestJokes = [
    "You know the state flower (Mildew)",
    "You feel guilty throwing aluminum cans or paper in the trash.",
    "Use the statement 'sun break' and know what it means.",
    "You know more than 10 ways to order coffee.",
    "You know more people who own boats than air conditioners.",
    "You feel overdressed wearing a suit to a nice restaurant.",
    "You stand on a deserted corner in the rain waiting for the 'Walk' Signal.",
    "You consider that if it has no snow or has not recently erupted, it is not a real mountain.",
    "You can taste the difference between Starbucks, Seattle's Best, and Veneto's.",
    "You know the difference between Chinook, Coho and Sockeye salmon.",
    "You know how to pronounce Sequim, Puyallup, Issaquah, Oregon, Yakima and Willamette.",
    "You consider swimming an indoor sport.",
    "You can tell the difference between Japanese, Chinese and Thai food.",
    "In winter, you go to work in the dark and come home in the dark - while only working eight-hour days.",
    "You never go camping without waterproof matches and a poncho.",
    "You are not fazed by 'Today's forecast: showers followed by rain,' and 'Tomorrow's forecast: rain followed by showers.'",
    "You have no concept of humidity without precipitation.",
    "You know that Boring is a town in Oregon and not just a state of mind.",
    "You can point to at least two volcanoes, even if you cannot see through the cloud cover.",
    "You notice, 'The mountain is out' when it is a pretty day and you can actually see it.",
    "You put on your shorts when the temperature gets above 50, but still wear your hiking boots and parka.",
    "You switch to your sandals when it gets about 60, but keep the socks on.",
    "You have actually used your mountain bike on a mountain.",
    "You think people who use umbrellas are either wimps or tourists.",
    "You buy new sunglasses every year, because you cannot find the old ones after such a long time.",
    "You measure distance in hours.",
    "You often switch from 'heat' to 'a/c' in the same day.",
    "You design your kid's Halloween costume to fit under a raincoat.",
    "You know all the important seasons: Almost Winter, Winter, Still Raining (Spring), Road Construction (Summer), Deer & Elk season (Fall).",
    "You actually understood these jokes and will probably forward them!"
  ];

  const littleLeprechaunJoke = [
    "A little boy asked his teacher if he could go to the bathroom. She said yes.",
    "When he went to wipe, there was no toilet paper, so he used his hand.",
    "Back in class, the teacher asked, \"What do you have in your hand?\"",
    "The boy replied, \"A little leprechaun, and if I open my hand, he'll get scared away.\"",
    "He was sent to the principal's office. The principal asked, \"What do you have in your hand?\"",
    "The boy said, \"A little leprechaun, and if I open my hand, he'll get scared away.\"",
    "He was sent home. His mom asked, \"What do you have in your hand?\"",
    "The boy said, \"A little leprechaun, and if I open my hand, he'll get scared away.\"",
    "He was sent to his room. His dad came in and asked, \"What do you have in your hand?\"",
    "The boy said, \"A little leprechaun, and if I open my hand, he'll get scared away.\"",
    "His dad got mad and yelled, \"Open your hand NOW!\"",
    "The boy said, \"Oh great, Dad, now you scared the shit out of him!\""
  ];
  
  const samuraiJoke = [
    "Once upon a time a powerful Emperor of the Rising Sun advertised for a new Chief Samurai. After a year, only three applied for the job: a Japanese, a Chinese and a Jewish Samurai.",
    "\"Demonstrate your skills!\" commanded the Emperor.",
    "The Japanese samurai stepped forward, opened a tiny box and released a fly. He drew his samurai sword and Swish! The fly fell to the floor, neatly divided in two!",
    "\"What a feat!\" said the Emperor. \"Number Two Samurai, show me what you can do.\"",
    "The Chinese samurai smiled confidently, stepped forward and opened a tiny box, releasing a fly. He drew his samurai sword and Swish, Swish; the fly fell to the floor neatly quartered!",
    "\"That is skill!\" nodded the Emperor. \"How are you going to top that, Number Three Samurai?\"",
    "Number Three Samurai stepped forward, opened a tiny box releasing one fly, drew his Samurai sword and Swoooooosh! flourished his sword so mightily that a gust of wind blew through the room. But the fly was still buzzing around!",
    "In disappointment, the Emperor said, \"What kind of skill is that? The fly isn't even dead\"",
    "\"Dead, schemed,\" replied the Jewish Samurai. \"Dead is easy. Circumcision - THAT takes skill!\""
  ];
  
  const qaBlondeJokes =[
    { question: "What do you call a blonde with half a brain?", answer: "Gifted!" },
    { question: "How do blonde brain cells die?", answer: "Alone." },
    { question: "What do you call a blonde with 2 brain cells?", answer: "Pregnant." },
    { question: "How do you brainwash a blonde?", answer: "Give her a douche and shake her upside down." },
    { question: "What do you call it when a blonde dies their hair brunette?", answer: "Artificial intelligence." },
    { question: "How does a blonde part their hair?", answer: "(Action of scissoring legs apart) By doing the splits." },
    { question: "Why aren't blondes good cattle herders?", answer: "Because they can't even keep two calves together!" },
    { question: "What did the blonde's right leg say to the left leg?", answer: "Nothing. They've never met." },
    { question: "Why do blondes wash their hair in the sink?", answer: "Because, that's where you're supposed to wash vegetables!" },
    { question: "When does a brunette have 1/2 of a brain?", answer: "After a dye job." },
    { question: "Why didn't the blonde want a window seat on the plane?", answer: "She'd just blow dried her hair and she didn't want it blown around too much." },
    { question: "Why do blondes wear their hair up?", answer: "To catch everything that goes over their heads." },
    { question: "Why is it good to have a blonde passenger?", answer: "You can park in the handicap zone." },
    { question: "What was the blonde psychic's greatest achievement?", answer: "An IN-body experience!" },
    { question: "Why is a blonde like a turtle?", answer: "They both get fucked up when they're on their back." },
    { question: "What do Darren Millane (Collingwood footballer killed in a recent car crash) and a blonde have in common?", answer: "Put either of 'em in a car and their fucked." },
    { question: "What's a blonde's favorite nursery rhyme?", answer: "Humpme Dumpme." },
    { question: "How do you make a blonde's eyes light up?", answer: "Shine a flashlight in their ear." },
    { question: "How do you get a blondes eyes to twinkle?", answer: "Shine a torch in her ears." },
    { question: "Why should blondes not be given coffee breaks?", answer: "It takes too long to retrain them." },
    { question: "How can you tell if a blonde's been using the computer?", answer: "There's white-out on the screen." },
    { question: "How can you tell if another blonde's been using the computer?", answer: "There's writing on the white-out." },
    { question: "What's the difference between a blonde and a computer?", answer: "You only have to punch information into a computer once." },
    { question: "What do a blonde and your computer have in common?", answer: "You don't know how much either of them mean to you until they go down on you." },
    { question: "What did the blonde think of the new computer?", answer: "She didn't like it because she couldn't get channel 9." },
    { question: "Why do blondes wear shoulder pads?", answer: "(With a rocking of the head from side to side) I dunno!" },
    { question: "How do you kill a blonde?", answer: "Put spikes in their shoulder pads." },
    { question: "How do blondes pierce their ears?", answer: "They put tacks in their shoulder pads." }, 
    { question: "Why don't blondes eat Jell-O?", answer: "They can't figure out how to get two cups of water into those little packages." },
    { question: "What do you call a blonde with a dollar on the top of their head?", answer: "All you can eat, under a buck." },
    { question: "Why don't blondes eat pickles?", answer: "Because they can't get their head in the jar." },
    { question: "Why don't blondes eat bananas?", answer: "They can't find the zipper." },
    { question: "Why do blondes wear hoop earrings?", answer: "They have to have some place to rest their ankles." },
    { question: "Why do blondes where big hoop earrings?", answer: "To put their feet through." },
    { question: "What does a blonde put behind her ears to make her more attractive?", answer: "Her ankles." },
    { question: "How can you tell if a blonde has been in your refrigerator?", answer: "By the lipstick on your cucumbers." },
    { question: "Why don't blondes use vibrators?", answer: "They chip their teeth." },
    { question: "Why do blondes wear underwear?", answer: "They make good ankle warmers." },
    { question: "What do blondes do for foreplay?", answer: "Remove their underwear." },
    { question: "Why don't blondes in San Francisco wear short black mini skirts?", answer: "Cause their balls show!" },
    { question: "What's the mating call of the blonde?", answer: "I'm *sooo* drunk!" },
    { question: "What is the mating call of the ugly blonde?", answer: "(Screaming) 'I said: I'm drunk!'" },
    { question: "How did the blonde die ice fishing?", answer: "She was run over by the zambonis machine." },
    { question: "What's a brunette's mating call?", answer: "Has that blonde gone yet?" },
    { question: "What's a brunette's mating call?", answer: "When is that blonde bitch going to leave!?" },
    { question: "What's a brunette's mating call?", answer: "All the blondes have gone home!" },
    { question: "Why do blondes drive BMWs?", answer: "Because they can spell it." },
    { question: "Why do blondes like the GST?", answer: "(GST -- Goods and Services Tax now in effect in Canada) Because they can spell it." },
    { question: "What is 74 to a blonde?", answer: "69 plus G.S.T." },
    { question: "Why do blondes have TGIF on their shoes?", answer: "Toes Go In First." },
    { question: "Why do blondes have TGIF on their shirts?", answer: "Tits Go In Front." },
    { question: "What do you call a brunette with a blonde on either side?", answer: "An interpreter." },
    { question: "What do you call a blonde between two brunettes?", answer: "A mental block." },
    { question: "How do you change a blonde's mind?", answer: "Blow in her ear. Buy her another beer." },
    { question: "What do you say to a blonde that won't give in?", answer: "Have another beer." },
    { question: "What do blondes do with their arseholes in the morning?", answer: "Pack their lunch and send them to work." },
    { question: "What's the first thing a blonde does in the morning?", answer: "Introduces herself." },
    { question: "How does a blonde like her eggs in the morning?", answer: "Fertilized." },
    { question: "How does a blonde like her eggs?", answer: "Unfertilized." },
    { question: "What's the first thing a blonde does after sex?", answer: "Opens the car door." },
    { question: "How do blondes turn the light on after sex?", answer: "Kick open the car door." },
    { question: "Why do blondes like tilt steering?", answer: "More head room." },
    { question: "Why do blondes drive cars with sunroofs?", answer: "More leg room." },
    { question: "What is the worst thing about sex with a blonde?", answer: "Bucket seats." },
    { question: "What do blondes say after sex?", answer: "Thanks, Guys!" },
    { question: "What do blondes say after sex?", answer: "Are you boys all in the same band?" },
    { question: "What do blondes say after sex?", answer: "Do you guys all play for the same team?" },
    { question: "What do blondes say after sex?", answer: "Who were all those guys?" },
    { question: "Why is a blonde like a door knob?", answer: "Because everybody gets a turn." },
    { question: "Why is a blonde like railroad tracks?", answer: "Because she's been laid all over the country." },
    { question: "What important question does a blonde ask her mate before having sex?", answer: "Do you want this by the hour, or the flat rate?" },
    { question: "Why do blonde girls have trouble achieving orgasm?", answer: "*Who cares?*" },
    { question: "Why do blondes have orgasms?", answer: "So they know when to stop having sex!" },
    { question: "How do you tell when a blonde reaches orgasm?", answer: "She drops her nail-file!" },
    { question: "How do you tell when a blonde reaches orgasm?", answer: "She says, 'Next'" },
    { question: "How do you tell when a blonde reaches orgasm?", answer: "The next person in the queue taps you on the shoulder." },
    { question: "How do you tell when a blonde reaches orgasm?", answer: "She's had her clothes on for about 2  minutes." },
    { question: "How do you tell when a blonde reaches orgasm?", answer: "The batteries have run out." },
    { question: "What does a blonde say when you blow in their ear?", answer: "Thanks for the refill!" },
    { question: "What is it called when a blonde blows in another blonde's ear?", answer: "Data transfer." },
    { question: "Why do blondes use tampons with extra long strings?", answer: "So the crabs can go bungee-jumping." },
    { question: "How can you tell which blonde is the waitress?", answer: "She is the one with the tampon behind her ear,  wondering what she did with her pencil." },
    { question: "What did the blonde customer say to the buxom waitress (reading her nametag) ?", answer: "'Debbie'...that's cute. What did you name the other one ?" },
    { question: "Why do blondes have more fun?", answer: "Because they don't know any better. They are easier to keep amused." },
    { question: "How many blondes does it take to change a light bulb?", answer: "What's a light bulb?" },
    { question: "How many blondes does it take to change a light bulb?", answer: "One. She holds the bulb and the world revolves around her." },
    { question: "How many blondes does it take to change a light bulb?", answer: "Two. One to hold the Diet Pepsi, and one to call, 'Daaady!'" },
    { question: "What's a blonde's favorite wine?", answer: "Daaaddy, I want to go to Miaaami!" },
    { question: "What do you call a basement full of blondes?", answer: "A wine cellar." },
    { question: "Why are there no dumb brunettes?", answer: "Peroxide." },
    { question: "Why does NASA hire peroxide blondes?", answer: "They're doing research on black holes." },
    { question: "What does a peroxide blonde and a 747 have in common?", answer: "They both have a black box. Both have a cockpit." },
    { question: "What is the difference between a blonde and a 747?", answer: "Not everyone has been in a 747." },
    { question: "What's the difference between a blonde and a limousine?", answer: "Not everybody has been in a limo." },
    { question: "What does a blonde say when she gives birth?", answer: "Gee, Are you sure it's mine?" },
    { question: "What did the blonde say when she found out she was pregnant?", answer: "Are you sure it's mine?" },
    { question: "What do you call 10 blondes standing ear to ear?", answer: "A wind tunnel." },
    { question: "What do you call 15 blondes in a circle?", answer: "A dope ring." },
    { question: "Santa Claus, the Tooth Fairy, a dumb blonde, and a smart blonde are walking down the street when they spot a $10 bill. Who picks it up?", answer: "The dumb blonde! because, there is no such thing as Santa Claus, the tooth fairy, or a smart blonde." },
    { question: "Santa Claus, the Tooth Fairy, a dumb blonde, and a smart blonde are walking down the street when they spot a $10 bill. Who picks it up?", answer: "None of them. There is no such thing as Santa Claus, the Tooth Fairy or a smart blonde and the dumb blonde thought it was a gum wrapper." },
    { question: "Why did the blonde scale the glass wall?", answer: "To see what was on the other side." },
    { question: "What do you do when a blonde throes a hand grenade at you?", answer: "Pull the pin and throw it back." },
    { question: "Why do blondes take the pill?", answer: "So they know what day of the week it is." },
    { question: "Why did the blonde stop using the pill?", answer: "Because it kept falling out." },
    { question: "Why did the blonde have a sore navel?", answer: "Because her boyfriend was also blond!" },
    { question: "If a blonde and a brunette are tossed off a building, who hits the ground first?", answer: "The brunette. The blonde has to stop to ask for directions." },
    { question: "What happens when a blonde gets Alzheimers disease?", answer: "Her IQ goes up!" },
    { question: "What's the difference between Indiana and a blonde?", answer: "A blonde has larger hills and deeper valleys." },
    { question: "What's the difference between a blonde and a Porsche?", answer: "You don't lend the Porsche out to your friend." },
    { question: "What's the difference between a blonde and a toothbrush?", answer: "You don't let your best friend borrow your toothbrush." },
    { question: "What is the difference between butter and a blonde?", answer: "Butter is difficult to spread." },
    { question: "What is the difference between a blonde and a bowling ball?", answer: "You can only get three fingers in a bowling ball." },
    { question: "What is the difference between a blonde and a bowling ball?", answer: "You can't fit the blonde in the bowling ball." },
    { question: "What is the difference between a blonde and a bowling ball?", answer: "There is no difference. They're both round and have three holes to poke. You don't eat your bowling ball" },
    { question: "What is the difference between a blonde and a bowling ball?", answer: "You don't eat your bowling ball" },
    { question: "What do a bowling ball and a blonde have in common?", answer: "Chances are they'll both end up in the gutter." },
    { question: "What is the difference between a blonde and 'The Titanic'?", answer: "They know how many men went down on 'The Titanic'." },
    { question: "What is the difference between a smart blonde and Bigfoot?", answer: "Bigfoot has been spotted." },
    { question: "What's the difference between a blonde and a telephone?", answer: "It costs 30 cents to use a telephone." },
    { question: "What's the difference between a blonde and a guy?", answer: "The blonde has the higher sperm count." },
    { question: "What is the difference between a blonde and the Grand Old Duke of York?", answer: "The Grand Old Duke of York only 'had' 10000 men." },
    { question: "Why is a washing machine better than a blonde?", answer: "Because you can drop your load in a washing machine, and it won't follow you around for a week." },
    { question: "What do blondes and cow-pats have in common?", answer: "They both get easier to pick-up with age." },
    { question: "What does a screen door and a blonde have in common?", answer: "The more you bang it, the looser it gets." },
    { question: "What does a blonde and a beer bottle have in common?", answer: "They're both empty from the neck up." },
    { question: "What do blondes and spaghetti have in common?", answer: "They both wriggle when you eat them." },
    { question: "Why did the deaf blonde sit on a newspaper?", answer: "So she could lip read." },
    { question: "What do peroxide blondes and black men have in common?", answer: "They both have black roots." },
    { question: "How do you drown a blonde?", answer: "Put a mirror at the bottom of the pool." },
    { question: "Why did the blonde drown in the pool?", answer: "Someone left a scratch and sniff at the bottom of the pool." },
    { question: "Why do blondes have square boobs?", answer: "Because they forgot to take the tissues out of the box." },
    { question: "How many blondes does it take to make chocolate-chip cookies?", answer: "Two...one to make batter and one to peel the M&Ms." },
    { question: "How do you tell if a blonde did your landscaping?", answer: "The bushes are darker than the rest of the yard." },
    { question: "What did the blonde's mom say to her before the blonde's date?", answer: "If you're not in bed by 12, come home." },
    { question: "What's the blonde's cheer?", answer: " I'm blonde, I'm blonde, I'm B.L.O.N....ah, oh well.. I'm blonde, I'm blonde, yea yea yea..." },
    { question: "What do you get when you offer a blonde a penny for her thoughts?", answer: "Change." },
    { question: "How does a blonde moonwalk?", answer: "She pulls down her panties and slides her ass along the floor!" },
    { question: "Why do blondes find it difficult to marry?", answer: "Because you don't have to marry them for sex!" },
    { question: "What do you get when you cross a blonde and a gorilla?", answer: "Who knows, there is only so much a gorilla can be forced to do." },
    { question: "Why does a blonde only change her baby's diapers every month?", answer: "Because it says right on it 'good for up to 20 pounds.'" },
    { question: "How did the blonde try to kill the bird?", answer: "She threw it off a cliff." },
    { question: "How does a blonde kill a fish?", answer: "She drowns it." },
    { question: "Why did the blonde get so excited after she finished her jigsaw puzzle in only 6 months?", answer: "Because on the box it said From 2-4 years." },
    { question: "What do you say to a blonde with no arms and no legs?", answer: "Nice tits!" },
    { question: "How does a blonde high-5?", answer: "She smacks herself in the forehead." },
    { question: "How do you amuse a blonde for hours?", answer: "Write 'Please turn over' on both sides of a piece of paper." },
    { question: "Why aren't there many blonde gymnasts?", answer: "Because when they do the splits, they stick to the floor." },
    { question: "Why do blondes have legs?", answer: "So they don't get stuck to the ground. To get between the bedroom and the kitchen. So they don't leave trails, like little snails." },
    { question: "Why did the blonde go half way to Norway and then turn around and come home?", answer: "It took her that long to discover that a 14-inch Viking was a television." },
    { question: "What is the irritating part around a blonde's vagina?", answer: "The blonde! The other guys waiting their turn." },
    { question: "How do you describe a blonde, surrounded by drooling idiots?", answer: "Flattered." },
    { question: "Why do blondes always die before help arrives?", answer: "They always forget the '11' in '9-1-1'." },
    { question: "What did the blonde say when asked if she'd ever been picked up by 'the fuzz'?", answer: "No. But I've been swung around by the tits." },
    { question: "What do you call a swimming pool full of blondes?", answer: "Frosted Flakes." },
    { question: "What do you call 20 blondes in a freezer?", answer: "Frosted Flakes." },
    { question: "How does a blonde interpret 6.9?", answer: "A 69 interrupted by a period." },
    { question: "What's the difference between a blonde having her period and a terrorist?", answer: "You can negotiate with a terrorist." },
    { question: "What did the blonde say when she looked into a box of Cheerios?", answer: "Oh look! Donut seeds!" },
    { question: "Why do blondes have two more brain cells than a cow?", answer: "So they don't shit everywhere when you pull their tits. So that when you pull their tits, they don't moo." },
    { question: "Why don't blondes breast feed?", answer: "Because they always burn their nipples." },
    { question: "Did you hear about the blonde lesbian?", answer: "She kept having affairs with men!" },
    { question: "Why do blondes put their hair in ponytails?", answer: "To cover up the valve stem." },
    { question: "What did the blonde name her pet zebra?", answer: "Spot." },
    { question: "What do you call a fly buzzing inside a blonde's head?", answer: "A Space Invader." },
    { question: "What's a blondes' favourite rock group?", answer: "Air Supply." },
    { question: "What do you see when you look into a blonde's eyes?", answer: "The back of her head." },
    { question: "Why do blondes drive VW's?", answer: "Because they can't spell PORSCHE!" },
    { question: "How do you make a blonde laugh on Monday mornings?", answer: "Tell them a joke on Friday night!" },
    { question: "Why did God create blondes?", answer: "Because sheep can't bring beer from the fridge." },
    { question: "Why did God create brunettes?", answer: "Neither could the blondes." },
    { question: "What do you call a blonde in a tree with a brief case?", answer: "Branch Manager." },
    { question: "How can you tell that a blonde's having a bad day?", answer: "She has a tampon tucked behind her ear, and she can't find her pencil." },
    { question: "Why are only 2% of blondes touch-typists?", answer: "The rest are hunt'n peckers." },
    { question: "What do you call a blond mother-in-law?", answer: "An air bag." },
    { question: "Why should you never take a blonde out for coffee?", answer: "It's too hard to re-train them." },
    { question: "What do blondes wear behind their ears to attract men?", answer: "Their heels." },
    { question: "Why don't blondes have elevator jobs?", answer: "They don't know the route." },
    { question: "Why do blondes work seven days a week?", answer: "So you don't have to retrain them on Monday." },
    { question: "What's the difference between a blonde and an ironing board?", answer: "It's difficult to open the legs of an ironing board." },
    { question: "What is foreplay for a blonde?", answer: "Thirty minutes of begging." },
    { question: "What's the difference between a blonde and a broom closet?", answer: "Only two men fit inside a broom closet at once." },
    { question: "What's the difference between a blonde and a phone booth?", answer: "You need a quarter to use the phone. Only one person can use the phone at once." },
    { question: "What does the Bermuda Triangle and blondes have in common?", answer: "They've both swallowed a lot of semen." },
    { question: "What did the blonde say when she knocked over the priceless Ming vase?", answer: "It's OK Daddy, I'm not hurt." },
    { question: "How does a blonde commit suicide?", answer: "She gathers her clothes into a pile and jumps off." },
    { question: "How do you plant dope?", answer: "Bury a blonde." },
    { question: "Why did god give blonds 2% more brains than horses?", answer: "Because he didn't want them shitting in the streets during parades." },
    { question: "How do you get a one-armed blonde out of a tree?", answer: "Wave to her." },
    { question: "How does a blonde measure his/her IQ?", answer: "With a tire gauge! (da da dum)" },
    { question: "How does a blonde get pregnant?", answer: "And I thought blondes were dumb!" },
    { question: "What do you call a blonde with ESP and PMS?", answer: "A know-it-all bitch." },
    { question: "What's the difference between a counterfeit dollar and a skinny blonde?", answer: "One's a phony buck." },
    { question: "What's the difference between a chorus line of blondes and a magician?", answer: "A magician has a cunning array of stunts." },
    { question: "What is the best blonde secretary in the world to have?", answer: "One that never misses a period." },
    { question: "What does a blonde think an innuendo is?", answer: "An Italian suppository." },
    { question: "Why don't a blondes guts fall out of her twat when she stands?", answer: "Because the vacuum in her head keeps them in place." },
    { question: "What's the difference between having sex with a blonde and eating Jell-O?", answer: "Jell-O wiggles when you eat it." },
    { question: "What do you get when you cross a blonde and a lawyer?", answer: "I don't know, there are some things even a blonde won't do." },
    { question: "Why was the blonde wearing her sunglasses?", answer: "She was having sunny periods." },
    { question: "What two things in the air can get a blonde pregnant?", answer: "Her feet!" },
    { question: "How can you tell when a blonde is wearing pantyhose?", answer: "When she farts, her knees bag." },
    { question: "What's the disease that paralyzes blondes below the waist?", answer: "Marriage." },
    { question: "How is a blonde like a frying pan?", answer: "You have to get them hot before you put in the meat." },
    { question: "How do you describe the perfect blonde?", answer: "3 feet tall, no teeth, and a flat head to rest your beer on." },
    { question: "How do you confuse a blonde?", answer: "You don't. They're born that way." },
    { question: "How do ya paralyze a blonde from the neck down?", answer: "Marry her." },
    { question: "How did the blonde die drinking milk?", answer: "The cow fell on her." },
    { question: "How did the blonde burn her nose?", answer: "Bobbing for french fries." },
    { question: "How can you tell if a blonde writes mysteries?", answer: "She has a checkbook." },
    { question: "How can you tell when a FAX had been sent from a blonde?", answer: "There is a stamp on it." },
    { question: "How can you tell if a blonde is a good cook?", answer: "She gets the pop tarts out of the toaster in one piece." },
    { question: "What's the difference between a pit bull and a blonde with PMS?", answer: "Lipstick." },
    { question: "Why does a blonde insist on him wearing a condom?", answer: "So she can have a doggie bag for later." },
    { question: "Why do men like blonde jokes?", answer: "Because they can understand them." },
    { question: "Why do blondes like lightning?", answer: "They think someone is taking their picture." },
    { question: "Why do all blondes all have a dimple on their chin and a flat forehead?", answer: "Finger on chin-I don't know. Hits forehead-Oh I get it!" },
    { question: "Why do blondes have little holes all over their faces?", answer: "From eating with forks." },
    { question: "Why do blondes have big bellybuttons?", answer: "From dating blonde men." },
    { question: "Why do blondes wear tampons?", answer: "Because crabs like Bungie Jumping too." },
    { question: "But why do brunettes take the pill ?", answer: "Wishful Thinking." },
    { question: "Why don't blondes double recipes?", answer: "The oven doesn't go to 700 degrees." },
    { question: "Why don't blondes make good pharmacists?", answer: "They can't get the bottle into the typewriter." },
    { question: "Why don't blondes call 911 in an emergency?", answer: "They can't remember the number. She can't find the number 11 on the telephone buttons." },
    { question: "What do you call a blonde touching her toes?", answer: "A brunette with bad breath." },
    { question: "What does a blonde make best for dinner?", answer: "Reservations." },
    { question: "What do blondes do after they comb their hair?", answer: "They pull up their pants." },
    { question: "What do you call five blondes at the bottom of the pool?", answer: "Air bubbles." },
    { question: "What do you call 3 blondes, a chimp, and another blonde standing on a street corner?", answer: "4 f*cks, 4 f*cks, 4 f*cks, not for a zillion f*cks, 4 f*cks!" },
    { question: "What do you call a blonde lesbian?", answer: "A waste." },
    { question: "What do you call 4 blondes lying on the ground?", answer: "An air mattress." },
    { question: "What do you call an unmarried blond in a BMW?", answer: "Divorcee'" },
    { question: "What do you call a hooker and four blondes?", answer: "Regular price, four bucks, four bucks, four bucks, four bucks." },
    { question: "What does a blonde owl say?", answer: "What, what?" }, 
    { question: "What do you call a zit on a blonde's ass?", answer: "A brain tumor." },
    { question: "What do you get when you turn 3 blondes upside-down?", answer: "Two brunettes." },
    { question: "What do a blonde and President Gorbachev have in common?", answer: "They both got fucked by 10 men whilst on holiday." },
    { question: "What's the difference between a blonde and President Gorbachev?", answer: "He knows who the ten men were." },
    { question: "Why did the blonde scale the chain-link fence?", answer: "To see what was on the other side." },
    { question: "Why did they stop doing the 'WAVE' at BYU?", answer: "Too many blondes were drowning." },
    { question: "Why did the  blonde have tire tread marks on her back?", answer: "From crawlingacross the street when the sign said 'DON'T WALK'." },
    { question: "Why did the blonde keep a coat hanger in her back seat?", answer: "In case she locks the keys in her car." },
    { question: "Why did the blonde tip-toe past the medicine cabinet?", answer: "So she wouldn't wake up the sleeping pills." },
    { question: "Why did the blonde want to become a veterinarian?", answer: "Because she loved children." },
    { question: "If an blonde and a brunette jumped off a bridge, who would die first?", answer: "The brunette -- because the blonde would have to stop and ask for directions." },
    { question: "To a blonde, what is long and hard?", answer: "Grade 4." },
    { question: "What are the worst six years in a blonde's life?", answer: "Third Grade." },
    { question: "What is the difference between a blonde and a refrigerator?", answer: "A refrigerator doesn't fart when you pull your meat out of it." },
    { question: "What is the definition of gross ignorance?", answer: "144 blondes." },
    { question: "Why is 68 the maximum speed for blonds?", answer: "Because at 69 they blow a rod..." },
    { question: "Why won't they hire a blonde pharmacist?", answer: "They keep breaking the prescription bottles in the typewriters." },
    { question: "What is the definition of the perfect woman?", answer: "A deaf and dumb blonde nymphomaniac whose father owns a pub." },
    { question: "Why is a blonde like an old washing machine?", answer: "They both drip when they're fucked." },
    { question: "How would a blond punctuate the following?", answer: "'Fun fun fun worry worry worry' Fun period fun period fun NO PERIOD worry worry worry!" },
    { question: "Why is the blonde's brain the size of a pea in the morning?", answer: "It swells at night." },
    { question: "What's a blonde's idea of safe sex?", answer: "Locking the car door." },
    { question: "Why did the blonde keep failing her driver's test?", answer: "Because every time the door opened, she jumped into the back seat." },
    { question: "What did the blonde do when she heard that 90% of accidents occur around the home?", answer: "She moved." },
    { question: "What's five miles long and has an IQ of forty?", answer: "A blonde parade." },
    { question: "Why is it okay for blondes to catch cold?", answer: "They don't have to worry about blowing their brains out." },
    { question: "Did you hear about the blonde who tried to blow up her husband's car?", answer: "She burned her lips on the tailpipe." },
    { question: "Why don't blondes like making KOOL-AID?", answer: "Because they can't fit 8 cups of water in the little packet." },
    { question: "How do you give a blonde a brain transplant?", answer: "Blow in her ear." },
    { question: "Why does a blonde have fur on the hem of her dress?", answer: "To keep her ankles warm. To keep her neck warm" },
    { question: "How can you tell a blonde had a bad day?", answer: "Her tampon is behind her ear and she doesn't know what she did with her cigarette." },
    { question: "What does a blonde say after multiple orgasms?", answer: "Way to go team!" },
    { question: "How can you tell if a blonde has a vibrator?", answer: "By the chipped tooth." },
    { question: "How do you keep a blonde in suspense?", answer: "(I'll tell you tomorrow.)" },
    { question: "Why do blondes wear shoulder pads?", answer: "To keep from bruising their ears." },
    { question: "Why does a blonds bra say T.G.I.F?", answer: "Tits go in first." },
    { question: "Why do blondes have vaginas?", answer: "So guys will talk to them at parties." },
    { question: "What do you call a blonde wearing a leather jacket on a motorcycle?", answer: "Rebel without a clue." },
    { question: "WHAT DO YOU CALL A BLONDE WITH A RUNNY NOSE?", answer: "Full." },
    { question: "WHY DON'T BLONDES BREASTFEED THEIR BABIES?", answer: "It hurts too much when they boil their nipples." },
    { question: "What does a blonde answer to the question 'Are you sexually active?'", answer: "No, I just lie there." },
    { question: "What's the first thing a blonde says in the morning?", answer: "Thanks, guys..." },
    { question: "WHAT  DO YOU CALL 10 BLONDES AT THE BOTTOM OF THE POOL?", answer: "AIR POCKETs." },
    { question: "Why do blondes hate M&Ms?", answer: "They're too hard to peel." },
    { question: "What did Jimmy Swaggart pay for his prostitute and her four blonde friends?", answer: "Regular price, four bucks, four bucks, four bucks, four bucks." },
    { question: "What does 'Bones' McCoy say before he performs brain surgery on a blonde?", answer: "Space. The final frontier......" },
    { question: "How many blondes does it take to screw the entire Bengals team?", answer: "Just One... Boomer Esiason." },
    { question: "What's brown and red and black and blue?", answer: "A brunette who's told one too many blonde jokes." },
    { question: "What do you call a brunette and three blondes on a corner?", answer: "You don't, you see if you've got 3 condoms." }, 
    { question: "Why did the blonde keep ice cubes in the freezer?", answer: "So she could keep the refrigerator cold." },
    { question: "How did the blonde break her leg playing hockey with the Toronto Maple Leafs?", answer: "She fell out of the tree." },
    { question: "What can strike a blonde without her even knowing it?", answer: "A thought." },
    { question: "How many blondes does it take to play Hide and Seek?", answer: "One." },
    { question: "Why couldn't the blonde write the number ELEVEN ?", answer: "She didn't know what ONE came first..." },
    { question: "Why don't blondes talk when having sex?", answer: "Their mothers told them not to talk to strangers. Their mothers told them not with there mouths full." },
    { question: "What do you call a blonde with 90% of her intelligence gone?", answer: "Divorced." },
    { question: "What do you call a blonde without an asshole?", answer: "Divorced." },
    { question: "How many blondes does it take to make a circuit?", answer: "Two, One to stand in the bathtub, and another to pass her the blow dryer!" },
    { question: "How is a blonde like a postage stamp?", answer: "You lick'm, stick'em, and send'em on their way." },
    { question: "How do you describe 3 prostitutes and a blonde?", answer: "Ho, Ho, Ho, and to all a good night." },
    { question: "Did you hear what the blonde who was opening a new bar said when her lawyer explained to her that she needed a liquor license?", answer: "Oh, it's not gonna be THAT kind of a bar. That's disgusting!" },
    { question: "What do you call a bunch of blondes with yeast infections?", answer: "A wine and cheese party!" },
    { question: "Why did the blonde fail her drivers license ?", answer: "She wasn't used to the front seat!" },
    { question: "(Visual Joke) What did the blonde say when she tried driving stick for the first time?", answer: "'How do you shift this thing?' (you make jacking off motions)" },
    { question: "What does a blonde do if she is not in bed by 10?", answer: "She picks up her purse and goes home." },
    { question: "Where do blondes go to meet their relatives?", answer: "The vegetable garden." },
    { question: "How many blondes does it take to play tag?", answer: "One." },
    { question: "Why did the blonde want to become a veterinarian?", answer: "She liked kids..." },
    { question: "What do you call four Blondes in a Volkswagen?", answer: "Far-from-thinkin" },
    { question: "Why don't they let Blondes swim in the ocean?", answer: "Because they can't get the smell out of the tuna." },
    { question: "What happened to the blonde tap dancer?", answer: "She slipped off and fell down the drain." },
    { question: "Did you hear about the blonde who dropped out of nursing school?", answer: "She was doing great until she found out she would have to perform the Hymenlick Maneuver." },
    { question: "Did you hear about the blond skydiver?", answer: "She missed the Earth!" },
    { question: "What do a moped and a blond have in common?", answer: "They're both fun to ride until a friend sees you on one." },
    { question: "How can you tell when a blonde rejects a new brain transplant?", answer: "She sneezes." },
    { question: "What did the dumb blonde say when told that 'Scheherezade' was composed by Rimsky-Korsakov?", answer: "Why'd his mom choose to call him Rimsky of all names ?!!?" },
    { question: "What did the Blonde get on her A.C.T.?", answer: "Nail polish!" },
    { question: "What's the fastest way to get a blonde pregnant?", answer: "Take her to the petting zoo." },
    { question: "How do you get a blonde to climb on the roof?", answer: "Tell her that the drinks are on the house" },
    { question: "What is the connection between a blonde and a halogen headlamp?", answer: "They both get screwed on the front of a Ford Escort." },
    { question: "What do you call it when a blonde gets taken over by a demon?", answer: "A vacant possession." },
    { question: "What did the blonde's dentist find?", answer: "Teeth in the cavity." },
    { question: "What is a blonde doing when she holds her hands tightly over her ears?", answer: "She's trying to hold on to a thought." },
    { question: "What does a car fatality and a blonde have in common?", answer: "Put either in a car and their fucked." },
    { question: "What is a blonde's idea of safe sex?", answer: "A padded dash." },
    { question: "Why do blondes use white-out on their computer screens?", answer: "They couldn't find their eraser." },
    { question: "What is the most difficult thing to teach a blonde?", answer: "To count to twenty-eight. (Cycle of a period)." },
    { question: "What do you do when a blonde throws a pin at you?", answer: "Run like hell...she's got a hand grenade in her mouth." },
    { question: "Why did the blonde cross the road?", answer: "She wanted to see the geese because she heard honking!" },
    { question: "Why was the blonde confused after giving birth to twins?", answer: "She couldn't figure out who the other mother was." },
    { question: "How do you know whether or not the blonde you slept with last night gave you a good blow-job?", answer: "The sheets are sucked up your ass." }, 
    { question: "Why did the blonde stare at the frozen orange juice can for 2 hours?", answer: "Because it said 'concentrate'." },
    { question: "Why do blondes occupy about 90% of the net bandwidth?", answer: "Because they keep accidentally deleting their copies of the Blonde Joke" }
  ];

  const blondeJokes = [
    (<div>A blonde ordered a pizza and the clerk asked if he should cut it in six or twelve pieces. "Six, please. I could never eat twelve pieces."</div>),
    (<div>A blonde is walking down the street with a pig under her arm. She passes a person who asks "Where did you get that?" The pig says, "I won her in a raffle!"</div>),
    (<div>Teller: Why did the blonde move to L.A.?<br/><br/>Blonde: I don't know. Why?<br/><br/>Teller: It was easier to spell.<br/><br/>Blonde: Easier than what?</div>),
    (<div>A brunette, a redhead, and a blonde are on a boat that capsizes near an island.<br/><br/>The brunette estimated about 20 miles to shore. So she announced, "I'm going to try to swim to shore." So she swam out five miles, and got really tired. She swam out ten miles from the island, and she was too tired to go on, so she drowned.<br/><br/>The second one, the redhead, said to herself, "I wonder if she made it." I guess it's better to try to get to the mainland than stay here and starve." So she attempts to swim out. The redhead had a lot more endurance than the brunette, as she swam out 10 miles before she even got tired. After 15 miles, she was too tired to go on, so she drowned.<br/><br/>So the blonde thought to herself, "I wonder if they made it! I think I'd better try to make it, too." So she swam out 5 miles, ten miles, 15 miles, NINETEEN miles from the island. The shore was just in sight, but she said, "I'm too tired to go on!" So she swam back.</div>),
    (<div>This blonde and her boyfriend were sitting in a hot tub when the blonde said to her boyfriend, "Is it true that if you pull you finger out, I'll sink?"</div>),
    (<div>Two blondes were walking through the woods when one looked down and said "Oh, look at the deer tracks." The other blonde looks and says "Those aren't deer tracks, those are wolf tracks." "No. Those are deer tracks." They keep arguing, and arguing, and one half hour later they were both killed by a train.</div>),
    (<div>The blonde came running downstairs, crying. Her mother asked what was wrong and the blonde said her boyfriend had just dropped her. Her mother (a blonde) nodded wisely and proceeded to tell her about the birds and the bees and the blonde said: "No ma. I can fuck and suck with the best of them. But he says I can't cook."</div>),
    (<div>Two blondes observed in a parking lot trying to unlock the door of their Mercedes with a coat hanger.<br/><br/>Blonde#1: I can't seem to get this door unlocked!<br/><br/>Blonde#2: Well, you'd better hurry up and try harder, its starting to rain and the top is down!</div>),
    (<div>A blonde was walking along, when she looked up to observe a bird flying overhead. Suddenly, the bird drops a load when it was directly over her. The blonde says, "Good thing I had my mouth open, or that would've hit me right in the face!!!"</div>),
    (<div>A dumb Blonde died and went to Heaven. When she got to the Pearly Gates, she met Saint Peter who said, "Before you get to come into Heaven, you have to pass a test." "Oh, No!" she said but Saint Peter said not to worry he'd make it easy. "Who was God's son?" said Saint Peter. The dumb Blonde thought for a few minutes and said "Andy!" "That's interesting... What made you say that?" said Saint Peter Then She started to sing "Andy walks with me! Andy talks with me! Andy tells me..."</div>),
    (<div>Did you hear about the blonde that went to library and checked out a book called "How to Hug"? Got back to the dorm and found out it was volume seven of the encyclopedia...</div>),
    (<div>A blonde's response to the comment, "THINK about it!": "I don't have to think -- I'm blonde!"</div>),
    (<div>A blonde, a brunette and a redhead went into a bar and asked the bartender:<br/><br/>Brunette: "I'll have a B and C."<br/>Bartender: "What is a B and C?".<br/>Brunette: "Bourbon and Coke."<br/>Redhead: "And, I'll have a G and T."<br/>Bartender: "What's a G and T?"<br/>Redhead: "Gin and tonic."<br/>Blonde: "I'll have a 15."<br/>Bartender: "What's a 15?"<br/>Blonde: "7 and 7"</div>),
    (<div>A government study has shown that blondes do have more fun - they just don't remember who with.</div>),
    (<div>Two blondes were driving along a road by a wheat field when they saw a blonde in the middle of the field rowing a row boat. The driver blonde turned to her friend and said "You know - it's blondes like that that give us a bad name!" To this the other blonde replied "I know it, and if I knew how to swim I'd go out there and drown her."</div>),
    (<div>I told my blonde girlfriend that I was going skeet shooting. She told me she didn't know how to cook them.</div>),
    (<div>A painting contractor was speaking with a woman about her job.<br/><br/>In the first room she said she would like a pale blue. The contractor wrote this down and went to the window, opened it, and yelled out "GREEN SIDE UP!"<br/><br/>In the second room she told the painter she would like it painted in a soft yellow. He wrote this on his pad, walked to the window, opened it, and yelled "GREEN SIDE UP!" The lady was somewhat curious but she said nothing.<br/><br/>In the third room she said she would like it painted a warm rose color. The painter wrote this down, walked to the window, opened it and yelled "GREEN SIDE UP!"<br/><br/>The lady then asked him, "Why do you keep yelling 'green side up'?"<br/><br/>"I'm sorry," came the reply. "But I have a crew of blondes laying sod across the street."</div>),
    (<div>Two brunettes and a blonde are in the hospital awaiting the arrival of their first children.<br/><br/>The 1st brunette says, "I just know I'm going to have a girl, 'cuz I conceived while I was on my back".<br/><br/>The 2nd brunette says, "Mine's going to be a boy, 'cuz I was on top during conception".<br/><br/>The blonde says, "Uh-oh! I'm going to have a puppy!"</div>),
    (<div>Blondes... They take a lickin', and keep on... Lickin!</div>),
    (<div>Did you hear the one about the blonde who thought that "love handles" referred to her ears?</div>),
    (<div>Confucious say; blonde who fly upside down have crack up.</div>),
    (<div>At a carwash in Burbank, there were two identical Hondas coming out at the same time. A beautiful blond woman jumps into one and takes off, leaving its owner rather perplexed. About three minutes latter, she reappears at the car wash yelling, "who ripped off my car phone!"</div>),
    (<div>A blonde to the deodorant display in a store and tells the clerk, "I need to buy some deodorant for my husband." "Does he use the ball kind?" enquired the clerk. "No," replied the blonde, "The kind for under his arms."</div>),
    (<div>Another blonde in the porno shop:<br/><br/>She asks, "How much for the white dildo?"<br/>He answers, "$35."<br/>She: "How much for the black one?"<br/>He: "$35 for the black one, $35 for the white one."<br/>She: "I think I'll take the black one. I've never had a black one before." She pays him, and off she goes. A little bit later a black woman comes in and asks "How much for the black dildo?"<br/>He: "$35."<br/>She: "How much for the white one?"<br/>He: "$35 for the white one, $35 for the black one."<br/>She: "Hmmm...I think I'll take the white one. I've never had a white one before..." She pays him, and off she goes. About an hour later a young blonde woman comes in and asks, "How much are your dildos?"<br/>He: "$35 for the white, $35 for the black."<br/>She: "Hmmmmm....how much is that plaid one on the shelf?"<br/>He: "Well, that's a very special dildo...it'll cost you $165." She thinks for a moment and answers, "I'll take the plaid one, I've never had a plaid one before...." She pays him, and off she goes. Finally, the guy's boss returns and asks, "How did you do while I was gone?" To which the salesman responded, "I did really good, I sold one white dildo, one black dildo, and I sold your thermos for $165!"</div>),
    (<div>After many hours of extremely acrobatic and exhausting sex with a blonde he had just picked up, a man goes into the kitchen for some food to replenish his justspent energy. He pours himself a glass of milk and right before drinking it, herealizes his manhood is still pretty hot, so he sticks it in the glass to cool it off. Just then the blonde walks in and says, "Oh, I always wondered how you refilled those."</div>),
    (<div>Blonde Medical Terminology<br/><br/>Anally -- occurring yearly<br/>Artery -- study of paintings<br/>Bacteria -- back door of cafeteria<br/>Barium -- what doctors do when treatment fails<br/>Bowel -- letter like A.E.I.O.U<br/>Caesarian section -- district in Rome<br/>Cat scan -- searching for kitty<br/>Cauterize -- Made eye contact with her<br/>Colic -- sheep dog Coma -- a punctuation mark<br/>Congenital -- friendly<br/>D&C -- where Washington is<br/>Diarrhea -- journal of daily events<br/>Dilate -- to live long<br/>Enema -- not a friend<br/>Fester -- quicker<br/>Fibula -- a small lie<br/>Genital -- non-Jewish<br/>G.I. Series -- soldiers' ball game<br/>Grippe -- suitcase<br/>Hangnail -- coathook<br/>Impotent -- distinguished, well known<br/>Intense pain -- torture in a teepee<br/>Labour pain -- got hurt at work<br/>Medical staff -- doctor's cane<br/>Morbid -- higher offer<br/>Nitrate -- cheaper than day rate<br/>Node -- was aware of<br/>Outpatient -- person who had fainted<br/>Pap smear -- fatherhood test<br/>Pelvis -- cousin of Elvis<br/>Post operative -- letter carrier<br/>Protein -- favouring young people<br/>Rectum -- damn near killed 'em<br/>Recovery room -- place to do upholstery<br/>Rheumatic -- amorous<br/>Scar -- rolled tobacco leaf<br/>Secretion -- hiding anything<br/>Seizure -- Roman emperor<br/>Serology -- study of knighthood<br/>Tablet -- small tablet<br/>Terminal illness -- sickness at airport<br/>Tibia -- country in North Africa<br/>Tumour -- an extra pair<br/>Urine -- opposite of you're out<br/>Varicose -- located nearby<br/>Vein -- conceited</div>),
    (<div>Imitation of a blonde refuelling.. (Flap hand, blowing air into ears)</div>),
    (<div>A blonde and a brunette were talking one day. The brunette said that her boyfriend had a slight dandruff problem but she gave him "Head and Shoulders" and it cleared it up. The blonde asked inquisitively, "How do you give shoulders?"</div>),
    (<div>STATE OF OHIO <br/>DEPARTMENT OF INSURANCE <br/>451 HIGH STREET <br/>George Voinovich <br/>COLUMBUS, OH 43210 <br/>Ralph G. Pacheco Governor <br/>Phone (614) 445-8627 <br/>Director FAX (614) 445-3225 <br/><br/>BULLETIN NO. 91-92 ------------------ <br/>DATE: January 7, 1992 <br/>TO: All Ohio Insurance Agents <br/>FROM: Ohio Department of Insurance <br/>SUBJECT: Automobile Dimmer Switches <br/><br/>Pursuant to the Ohio Department of Motor Vehicles Act No. 97-12, all motor vehicles sold in the State of Ohio after February 15, 1992, will be required to have the headlight dimmer switch mounted to the floorboard. The dimmer switch must be mounted in a position accessible to operation by pressing the switch by the left foot. The switch must be far enough removed from the left foot pedals to avoid inadvertent operation or pedal confusion. Included in the above act and beginning June 1, 1992, all other vehicles with steering column mounted dimmer switches must be retrofitted with a floorboard mounted dimmer switch of the type described above. The steering column mounted dimmer switch must be disabled or removed from the vehicle. Vehicles which have not made this change will fail the forthcoming Ohio Safety Inspection program which will begin on this date. It is recognized that this will cause some hardship for the driving public. However, this change is being made in the interest of public safety. Ohio DMV Act 92-13 will revert all Ohio motor vehicles to the prevalent dimmer system in use prior to the influx of foreign market vehicles. A recent study entitled the "Inflation Sequence in Ohio Nightime Highway Traffic Accidents" was conducted jointly by the Ohio Department of Motor Vehicles and the Ohio Department of Motor Vehicle Research. It has shown that 96% of all Ohio nightime highway accidents are caused by a blonde getting her foot caught in the steering wheel........</div>),
    (<div>A blonde and a brunette are skydiving. The brunette jumps out the plane and pulls the cord - nothing happens. She pulls the emergency cord and still nothing. The blonde jumps out of the plane and yells "Oh! So you wanna race, huh?"</div>),
    (<div>A blonde working in a office is constantly being kidded about how dumb she is; so one evening she goes home and studies a map of the United States. The next day she goes into the office and announces that she knows all 50 states and their capitols. One of her office mates says, "OK, what's the capitol of Wyoming?" and the blonde replies, "W."</div>)
  ];

  const comedySections = [
    (
      <>
        <p align="center">
          <font size="5">It's Good to Be a Man! <br/></font>
          <font size="3">Sent to me by a Woman.</font>
        </p>
        <div className="left-justify">
          <font size="4">
            <ul>
              {goodToBeAManJokes.map((joke, index) => (
                <li key={index}>
                  <div>{joke}</div>
                </li>
              ))}
            </ul>
          </font>
        </div>
      </>
    ),
    (
      <>
        {repairmanJoke.map((line, index) => (
          <p className="left-justify" key={index}>
            <font size="4">{line}</font>
          </p>
        ))}
      </>
    ),
    (
      <>
        <p className="left-justify">
          <font size="4">
            This is a true story from the Word Perfect Helpline which was transcribed from a recording monitoring the customer care department. Needless to say the HelpDesk employee was fired; however, he/she is currently suing the WordPerfect organization for "Termination without Cause." I think this guy should have been promoted, not fired.
          </font>
        </p>
        <p className="left-justify">
          <font size="4">
            Actual dialogue of a former WordPerfect Customer Support employee (now I know why they record these conversations)!
          </font>
        </p>
        <div className="conversation">
          {wordperfectConversation.map((message, index) => (
            <div key={index} className={`bubble ${message.direction}`}>
              <font size="4">{message.text}</font>
            </div>
          ))}
        </div>
      </>
    ),
    (
      <>
        <font size="4">
          <p className="left-justify">A Marine Colonel on his way home from work at the Pentagon came to a dead halt in traffic and thought to himself, "Wow, this traffic seems worse than usual. Nothing's even moving." He notices a police officer walking back  and forth between the lines of cars, so he rolls down his window and asks,  "Officer, what's the hold up?"</p>
          <p className="left-justify">The Officer replies, "The President is just so depressed about the thought of moving with Hillary to New York that he stopped his motorcade in the middle of the Beltway and he's threatening to douse himself in gasoline and set himself on fire. He says his family hates him and he  doesn't have the money to pay for the new house. I'm walking around taking up a collection for him."</p>
          <p className="left-justify">"Oh really? How much have you collected so far?", the Marine asks?</p>
          <p className="left-justify">"So far about three hundred gallons, but a lot of folks are still siphoning." responded the officer. HA!</p>
        </font>
      </>
    ),
    (
      <>
        <font size="4">
          <p className="left-justify">
            The tribal wisdom of the Dakota Indians, passed on from generation to 
            generation, says that, when you discover that you are riding a dead horse, 
            the best strategy is to dismount.
          </p>      
          <p className="left-justify">
            In modern education, government, and business, however, a whole range 
            of far more advanced and &quot;enlightened&quot; strategies are often employed, 
            such as:
          </p>
          <div className="left-justify">
            <ul>
            {horseJokes.map((joke, index) => (
              <li key={index}>
              <div>{joke}</div>
              </li>
            ))}
            </ul>
          </div>
        </font>
      </>
    ),
    (
      <>
        <p align="center">
          <font size="5">
            True American Criminals
          </font>
        </p>
  
        <font size="4">     
          {stateJokes.map((joke, index) => (
            <p className="left-justify" key={index}> <strong>{joke.state}</strong>: {joke.content} </p>
          ))}    
        </font>
      </>
    ),
    (
      <>
        <p align="center">
          <font size="5">
          The Complete Set Of Blonde Jokes
          </font>
        </p>
  
        <div className="left-justify">
          <font size="4">            
            <ul>
              {blondeJokes.map((section, index) => (
                <li key={index}>
                  {section}
                </li>
              ))}
            </ul>
          </font>
        </div>
        <div className="left-justify">
          {qaBlondeJokes.map((joke, index) => (
            <div key={index}>
            <font size="4"><strong>Q</strong> - {joke.question}<br/><strong>A</strong> - {joke.answer}<br/><br/> </font>
            </div>
          ))}
        </div>
      </>
    ),
    (
      <>
        {threeGirlsJoke.map((line, index) => (
          <p className="left-justify" key={index}>
            <font size="4">{line}</font>
          </p>
        ))}
      </>
    ),
    (
      <>
        <p align="center">
          <font size="5">
            HOW TO KEEP A HEALTHY LEVEL OF INSANITY
          </font>
        </p>
        <div className="left-justify">
          <font size="4">
            <ul>
            {insanityJokes.map((joke, index) => ( <li key={index}> <div>{joke}</div> </li> ))}
            </ul>
          </font>
        </div>
      </>
    ),
    (
      <>
        <p className="left-justify">
          <font size="4">
            An Anagram, as you all know, is a word or phrase made by transposing or 
            rearranging the letters of another word or phrase. The following are 
            exceptionally clever. Someone out there either has far too much spare 
            time or is deadly at Scrabble. (Wait till you see the last one!)
          </font>
        </p>
        {anagramJokes.map((anagram, index) => (
          <p className="left-justify" key={index}> <strong>{anagram.original}</strong>: {anagram.rearranged}</p>
        ))}
      </>
    ),
    (
      <>
        <p className="left-justify">
          <font size="4">
            A cardiac specialist died and at his funeral the coffin was placed in front of a huge mock up of a heart made up of flowers.
          </font>
        </p>
        <p className="left-justify">
          <font size="4">
            When the pastor finished with the sermon and eulogy, and after everyone said their good-byes, the heart opened, the coffin rolled inside and the heart closed. Just then one of the mourners burst into laughter.
          </font>
        </p>
        <p className="left-justify">
          <font size="4">
            The guy next to him asked: 
          </font>
        </p>
        <div className="conversation">
          {funeralConversation.map((message, index) => (
            <div key={index} className={`bubble ${message.direction}`}>
              <font size="4">{message.text}</font>
            </div>
          ))}
        </div>
      </>
    ),
    (
      <>
        <p align="center">
          <font size="5">
            Nativity Scene
          </font>
        </p>
        <p className="left-justify">
          <font size="4">
            The Supreme Court has ruled that there cannot be a nativity scene in 
            Washington, D.C. this Christmas. This isn't for any religious reason. 
            They simply have not been able to find three wise men and a virgin in 
            the Nation's capitol.
          </font>
        </p>
        <p className="left-justify">
          <font size="4">
            There was no problem however finding enough Asses to fill the stable.
          </font>
        </p>
      </>
    ),
    (
      <>
        <p align="center">
          <font size="5">
            Lessons to Learn
          </font>
        </p>
      
        {lessonsToLearn.map((lesson, index) => (
          <div className="left-justify" key={index}>
            <font size="4">
              <strong>Story #{index+1}</strong><br/>
              {lesson.story}<br/><br/>
              <strong>Lesson Learned #{index+1}</strong><br/>
              {Array.isArray(lesson.lesson) ? (
                <ol>
                {lesson.lesson.map((point, idx) => (
                  <li key={idx}>{point}</li>
                ))}
                </ol>
              ) : (
                lesson.lesson
              )}<br/><br/>
            </font>
          </div>
        ))}
      </>
    ),
    (
      <>
        <p align="center">
          <font size="5">
            PROVERBS FOR 2001
          </font>
        </p>
        <div className="left-justify">
          <font size="4">
            <ul>
            {proverbsJokes.map((proverb, index) => ( <li key={index}> <div>{proverb}</div> </li> ))}
            </ul>
          </font>
        </div>
      </>
    ),
    (
      <>
        <p align="center">
          <font size="5">
            The Little Leprechaun
          </font>
        </p>
        {littleLeprechaunJoke.map((line, index) => (
          <p className="left-justify" key={index}>
            <font size="4">{line}</font>
          </p>
        ))}
      </>
    ),
    (
      <>
        {samuraiJoke.map((line, index) => (
          <p className="left-justify" key={index}>
            <font size="4">{line}</font>
          </p>
        ))}
      </>
    ),
    (
      <>
        <p align="center">
      <font size="5">
        Pearls of Wisdom
      </font>
      </p>      
      <font size="4">
      <div className="left-justify">
        <ul>
          {pearlsOfWisdomJokes.map((pearl, index) => (
            <li key={index}> <div>{pearl}</div> </li>
          ))}
        </ul>
      </div>
      </font>
      </>
    ),
    (
      <>
        <p>
          <font size="5">
            Idiots are alive and well...
          </font>
        </p>      
        <div className="left-justify">
          {idiotJokes.map((joke, index) => (
            <div key={index}>
            <font size="4"><strong>{joke.category}:</strong><br/>{joke.content}<br/><br/> </font>
            </div>
          ))}
        </div>
      </>
    ),
    (
      <>
        <p> <VideoWithPreview src={kidstoned} width={320} height={240} title="Kid Stoned Chewable Valium" /> </p>
      </>
    ),
    (
      <>
        <p><font size="5">Girl Power</font></p>
        <div className="left-justify">
          {girlPowerJokes.map((joke, index) => (
            <div key={index}>
            <font size="4"><strong>Q</strong> - {joke.question}<br/><strong>A</strong> - {joke.answer}<br/><br/> </font>
            </div>
          ))}
        </div>
      </>
    ),
    (
      <>
        <p><font size="5">Buffalo Theory</font></p>
        <p><font size="4">I have not seen anyone explain this as well as Cliff Clavin, on Cheers. One afternoon at Cheers, Cliff Clavin was explaining the Buffalo Theory to his buddy Norm. Here's how it went: "Well ya see, Norm, it's like this... A herd of buffalo can only move as fast as the slowest buffalo. And when the herd is hunted, it is the slowest and weakest ones at the back that are killed first. This natural selection is good for the herd as a whole, because the general speed and health of the whole group keeps improving by the regular killing of the weakest members.<br/><br/>"In much the same way, the human brain can only operate as fast as the slowest brain cells. Excessive intake of alcohol, as we know, kills brain cells. But naturally, it attacks the slowest and weakest brain cells first. In this way, regular consumption of beer eliminates the weaker brain cells, making the brain a faster and more efficient machine. That's why you always feel smarter after a few beers."</font></p>
      </>
    ),
    (
      <>
        <p><font size="5">The Pacific Northwest According To Jeff Foxworthy</font></p>
        <font size="4">
          <div className="left-justify">
            <ul>
            {northwestJokes.map((joke, index) => ( <li key={index}> <div>{joke}</div> </li> ))}
            </ul>
          </div>
        </font>
      </>
    ),
    (
      <MyImage src={Canadian_View_of_US} alt="Canadian View of US" />
    ),
    (
      <p align="center">
        <font size="4">
          <a href="http://www.pimpdaddy.com/yomama-default.shtml" target="_blank" rel="noreferrer">Yo Mama's</a>
        </font>
      </p>
    )
  ];

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const shuffledSections = shuffleArray([...comedySections]);

  return (
    <Content>
      <p><font size="6">Jokes and / or Funny links</font></p>
      <p><font size="5">Not resposible for any injuries due to laughing too hard. If you have any good jokes or links please email them to me at <a href="mailto:tom@tomsspot.com">tom@TomsSpot.com</a></font></p>

      <hr/>

      {shuffledSections.map((section, index) => (
        <React.Fragment key={index}>
          {section}
          <hr/>
        </React.Fragment>
      ))}

      <p><font size="5">Your Joke Here</font></p>
    </Content>
  );
}

//addRoute('/about-me-comedy-blonde-jokes', <ComedyBlodeJokes />);

export default Comedy;
